import React, { useState, useEffect } from "react";
import { bool, func, object } from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { formatTime } from "../../../utils/format_time.jsx";
import "./AuthExpirationAlert.css";

const TIMER_DURATION = 5 * 60 * 1000; // 5 minutes

const propTypes = {
  open: bool,
  onClose: func,
  onStaySignedIn: func,
  // from withRouter
  history: object,
  location: object
};

const AuthExpirationAlert = ({
  open,
  onClose,
  onStaySignedIn,
  history,
  location
}) => {
  const timerRef = React.useRef();
  const intl = useIntl();
  // STATE

  const [timer, setTimer] = useState(1000);

  // EFFECTS

  useEffect(() => {
    if (open) {
      setTimer(TIMER_DURATION);
      timerRef.current = setInterval(() => {
        setTimer(time => {
          if (time <= 0) {
            history.push({
              pathname: "/login",
              state: { nextPathname: location.pathname, sessionExpired: true }
            });
            return 1000;
          } else {
            return time - 1000;
          }
        });
      }, 1000);
      return () => clearInterval(timerRef.current);
    } else {
      clearInterval(timerRef.current);
    }
  }, [history, location.pathname, open]);

  // HANDLES

  const handleLogout = async () => {
    onClose();
    window.sessionStorage.clear();
    // a reload resets the client store
    window.location.reload();
  };

  const handleStaySignedIn = () => {
    onStaySignedIn();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        {intl.messages["expire.title"] || "Your session is about to expire"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.messages["expire.content1"] ||
            "Due to inactivity you will be logged out in"}
          <span className="exp-time"> {formatTime(timer)}.</span>
          <br />
          {intl.messages["expire.content2"] || "Do you want to stay signed in?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogout} color="primary">
          {intl.messages["expire.logout"] || "Log out now"}
        </Button>
        <Button
          onClick={handleStaySignedIn}
          variant="contained"
          color="primary"
        >
          {intl.messages["expire.stay"] || "Stay signed in"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AuthExpirationAlert.propTypes = propTypes;

export default withRouter(AuthExpirationAlert);
