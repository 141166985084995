import React, { useState, useEffect } from "react";
// import _ from "lodash";
import { object } from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Paper, Button, TextField } from "@material-ui/core";
import { SITE_CONFIG } from "../../../config.js";
import Loading from "../../Loading/Loading.jsx";
import searchToObject from "../../../utils/searchToObject";
import CREATE_SIGNIN_CREDENTIALS from "../../../queries/mutations/CREATE_SIGNIN_CREDENTIALS.graphql";
import UPDATE_USERS from "../../../queries/mutations/UPDATE_USERS.graphql";
import { FormattedMessage, useIntl } from "react-intl";

import useInput from "../../../hooks/useFormInput";

import "./Signup.scss";

const propTypes = {
  history: object,
  location: object
};

const Signup = ({ history, location }) => {
  // REFS
  const intl = useIntl();
  const formRef = React.useRef();

  // STATE

  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, nameDispatch, nameError] = useInput("name", "");
  const [email, emailDispatch, emailError] = useInput("email", "");
  const [password, passwordDispatch, passwordError] = useInput("password", "");
  const [passwordConf, passwordConfDispatch, passwordConfError] = useInput(
    "password-confirm",
    ""
  );
  const [oobCode, setOobCode] = useState("");
  const [loading, setLoading] = useState(false);
  // const [finsished, setFinished] = useState(false);

  // EFFECTS

  const handleParams = async () => {
    try {
      window.sessionStorage.token = "";
      const query = searchToObject(location.search);
      // console.log(query)
      // console.log(query.email)
      const email = query.email;
      // const role = query.continueUrl.split("?")[1].split('&')[1].split('=')[1]
      // console.log(email)
      setOobCode(query.oobCode);
      emailDispatch({ type: "CHANGE", value: email });
    } catch (error) {
      console.error(error);
      setErrorMessage(
        intl.messages["formerror.expired"] ||
          "Error: Your reset link may have expired. You can request a new one here: "
      );
    }
  };

  useEffect(() => {
    handleParams();
  }, [location.search]);

  const [createCredentials] = useMutation(CREATE_SIGNIN_CREDENTIALS);
  const [updateUsers] = useMutation(UPDATE_USERS);
  // HANDLES

  const handleSubmit = e => {
    e.preventDefault();
    if (errorMessage) {
      setErrorMessage("");
    }
    setSubmitted(true);
    if (formRef.current.checkValidity()) {
      // handleCreateCompanyAndAccount();
      handleFinishSignup();
    }
  };

  const handleTexfieldKeyDown = event => {
    if (event.keyCode == 13) {
      handleSubmit(event);
    }
  };

  const handleFinishSignup = async () => {
    try {
      setLoading(true);

      const { data } = await createCredentials({
        variables: { oobCode, password }
      });
      console.log(data);
      const { token, expiresAt, userId } = data.createSignInCredentials;
      // console.log(token)
      // console.log(expiresAt)
      window.sessionStorage.token = token;
      window.sessionStorage.tokenExpiresAt = expiresAt;

      const options = {
        users: [
          {
            id: userId,
            name: name
          }
        ]
      };

      await updateUsers({
        variables: options
      });
      setLoading(false);
      // // history.push("/login");
      history.push("/dashboard");
    } catch (error) {
      console.error(error);
      setLoading(false);
      setErrorMessage(
        intl.messages["formerror.expired"] ||
          "Error: Your reset link may have expired. You can request a new one here: "
      );
    }
  };

  const styles = {
    paper: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: 20,
      width: 400
    }
  };
  return (
    <Paper elevation={2} style={styles.paper}>
      {loading && (
        <Loading
          message={intl.messages["signup.loading"] || "Creating your account"}
          greyOverlay
          delay={0}
        />
      )}
      <div>
        <img src={SITE_CONFIG.logo} width="200px" styleName="logo" />
      </div>
      <h3 styleName="title">
        <FormattedMessage
          id="signup.title"
          defaultMessage="Finish setting up your account"
        />
      </h3>
      {errorMessage && <p styleName="error">{errorMessage}</p>}
      <form ref={formRef} onSubmit={handleSubmit} noValidate>
        <TextField
          style={{ marginTop: 24 }}
          id="email"
          name="email"
          label={intl.messages["login.email"] || "Email"}
          type="email"
          placeholder={intl.messages["login.emailPrompt"] || "Enter email"}
          value={email}
          onChange={e =>
            emailDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{ onKeyDown: handleTexfieldKeyDown }}
          helperText={submitted && emailError}
          error={submitted && !!emailError}
          variant="outlined"
          autoComplete="off"
          fullWidth
          required
          disabled
        />
        <TextField
          style={{ marginTop: 24 }}
          id="name"
          label={<FormattedMessage id="create.name" defaultMessage="Name" />}
          type="text"
          placeholder={
            intl.messages["create.namePrompt"] || "Enter your full name"
          }
          value={name}
          onChange={e =>
            nameDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            minLength: 2,
            maxLength: 40
          }}
          helperText={submitted && nameError}
          error={submitted && !!nameError}
          variant="outlined"
          fullWidth
          required
          autoFocus
        />
        <TextField
          style={{ marginTop: 24 }}
          id="password"
          label={
            <FormattedMessage id="login.password" defaultMessage="Password" />
          }
          type="password"
          placeholder={
            intl.messages["login.passwordPrompt"] || "Enter password"
          }
          value={password}
          onChange={e =>
            passwordDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            minLength: 8,
            pattern: ".*([A-Z]).*",
            "data-error":
              intl.messages["formerror.caps"] || "Must have a capital letter"
          }}
          helperText={
            (submitted && passwordError) ||
            intl.messages["formerror.capsLength"] ||
            "Must have a capital letter and be at least 8 characters in length"
          }
          error={submitted && !!passwordError}
          variant="outlined"
          autoComplete="new-password"
          fullWidth
          required
        />
        <TextField
          style={{ marginTop: 24, marginBottom: 24 }}
          id="password-confirm"
          label={
            <FormattedMessage
              id="create.confirmPassword"
              defaultMessage="Confirm password"
            />
          }
          type="password"
          placeholder={
            intl.messages["create.passwordPrompt"] || "Enter password again"
          }
          value={passwordConf}
          onChange={e =>
            passwordConfDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            pattern: password,
            "data-error":
              intl.messages["formerror.match"] || "Must match password"
          }}
          helperText={submitted && passwordConfError}
          error={submitted && !!passwordConfError}
          variant="outlined"
          fullWidth
          required
        />
        <Button type="submit" color="primary" variant="contained" fullWidth>
          <FormattedMessage id="login.button" defaultMessage="Login" />
        </Button>
      </form>
    </Paper>
  );
};

Signup.propTypes = propTypes;

export default Signup;
