import { useReducer, useEffect } from "react";
import { useIntl } from "react-intl";

// This hook is for storing state of an input
// pass in the DOM id of the input and the initial value
// it returns an array with the value, a dispatch to update it, and an error message

function useFormInput(domId, initialValue = "") {
  if (!domId) {
    throw new Error("useFormInput must receive a DOM id for the input");
  }

  const intl = useIntl();

  const [state, dispatch] = useReducer((s, a) => reducer(s, a, domId, intl), {
    value: initialValue,
    error: ""
  });

  // gets inital error message
  useEffect(() => {
    if (document.getElementById(domId)) {
      dispatch({ type: "CHANGE", value: initialValue });
    }
  }, [domId, initialValue]);

  return [state.value, dispatch, state.error];
  // state = {value, error}
}

function reducer(state, action, domId, intl) {
  const inputEl = document.getElementById(domId);
  if (!inputEl) return { ...state, error: "No DOM element" };
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.value,
        error: getErrorMessage(inputEl, intl)
      };
    case "CHECK":
      return { ...state, error: getErrorMessage(inputEl, intl) };
    case "RESET":
      return { ...state, value: "", error: "" };
  }
}

function getErrorMessage(inputEl, intl) {
  if (inputEl.validity.valueMissing) {
    return intl.messages["inputerror.empty"] || "Cannot be empty";
  } else {
    let errorMessage = "";
    if (inputEl.validity.patternMismatch) {
      errorMessage = getAtt("data-error");
    }
    if (inputEl.validity.rangeOverflow) {
      errorMessage =
        intl.messages["inputerror.rangeOverflow"] + ` ${getAtt("min")}` ||
        `Must be no more than ${getAtt("min")}.`;
    }
    if (inputEl.validity.rangeUnderflow) {
      errorMessage =
        intl.messages["inputerror.rangeUnderflow"] + ` ${getAtt("min")}` ||
        `Must be at least ${getAtt("min")}.`;
    }
    if (inputEl.validity.tooLong) {
      errorMessage =
        (intl.messages["inputerror.tooLong1"] &&
          (errorMessage ? `${errorMessage} y n` : "N") +
            `${intl.messages["inputerror.tooLong1"]} ${getAtt("minlength")} ${
              intl.messages["inputerror.tooLong2"]
            }`) ||
        (errorMessage ? `${errorMessage} and m` : "M") +
          `ust have no more than ${getAtt("minlength")} characters`;
    }
    if (inputEl.validity.tooShort) {
      errorMessage =
        (intl.messages["inputerror.tooShort1"] &&
          (errorMessage ? `${errorMessage} y d` : "D") +
            `${intl.messages["inputerror.tooShort1"]} ${getAtt("minlength")} ${
              intl.messages["inputerror.tooShort2"]
            }`) ||
        (errorMessage ? `${errorMessage} and m` : "M") +
          `ust have at least ${getAtt("minlength")} characters`;
    }
    if (inputEl.validity.typeMismatch) {
      errorMessage =
        (intl.messages["inputerror.typeMismatch1"] &&
          (errorMessage ? `${errorMessage} y d` : "D") +
            `${intl.messages["inputerror.typeMismatch1"]} ${getAtt("name")} ${
              intl.messages["inputerror.typeMismatch2"]
            }`) ||
        (errorMessage ? `${errorMessage} and m` : "M") +
          `ust be a valid ${getAtt("name")}`;
    }
    errorMessage = errorMessage ? errorMessage + "." : "";
    return errorMessage;
  }
  function getAtt(attribute) {
    return inputEl.getAttribute(attribute) || "";
  }
}

export default useFormInput;
