import React, { useState } from "react";
import { bool, func } from "prop-types";
import _ from "lodash";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/react-hooks";

import Dialog from "../../../Dialog.jsx";
import GET_USERS_BY_PREFIX from "../../../../queries/GET_USERS_BY_PREFIX.graphql";
import GET_USERS_BY_PREFIX_ID from "../../../../queries/GET_USERS_BY_PREFIX_ID.graphql";
import SIGN_IN_AS from "../../../../queries/mutations/SIGN_IN_AS.graphql";
import "./SignInAsUserDialog.scss";

const propTypes = {
  open: bool,
  onClose: func
};

const SignInAsUserDialog = ({ onClose }) => {
  const intl = useIntl();
  const [value, setValue] = useState(null);
  const [query, setQuery] = useState("");
  const [searchBy, setSearchBy] = useState("displayName");

  // QUERY

  const userOptions = {
    variables: {
      prefix: query.replace(/ \([A-Z]*\)$/, ""),
      roles: ["BASIC", "MANAGER", "ADMIN"],
      size: 10
    }
  };
  const { data: users, loading } = useQuery(
    searchBy === "id" ? GET_USERS_BY_PREFIX_ID : GET_USERS_BY_PREFIX,
    userOptions
  );

  const usersByName = _.get(users, "usersByName.edges", []);
  const usersByEmail = _.get(users, "usersByEmail.edges", []);
  const usersById = _.get(users, "usersById.edges", []);
  const usersByIdNullEmail = _.get(users, "usersByIdNullEmail.edges", []);
  const usersByIdNullName = _.get(users, "usersByIdNullName.edges", []);
  const allUsers = [
    ...usersByName,
    ...usersByEmail,
    ...usersById,
    ...usersByIdNullEmail,
    ...usersByIdNullName
  ].map(({ user }) => ({
    value: user.id,
    label: `${user.name || user.email || user.id} (${user.role})`
  }));
  const options = _.uniqBy(allUsers, agent => agent.value + agent.label);

  // const { values: options, loading } = useUniqueValues(
  //   { label: "Agent", type: "NESTED_LIST", value: "participants.agents" },
  //   query,
  //   10
  // );

  const [signInAs] = useMutation(SIGN_IN_AS);

  const handleConfirmWithValue = async () => {
    const { data } = await signInAs({ variables: { userId: value.value } });
    window.sessionStorage.token = data?.signInAs?.token;
    window.sessionStorage.tokenExpiresAt = data?.signInAs?.expiresAt;
    window.location.reload();
  };
  const handleSearchBy = event => {
    setSearchBy(event.target.value);
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Sign In As Other User</DialogTitle>
      <IconButton
        style={{ position: "absolute" }}
        styleName="icon-button"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ height: 518 }}>
        <DialogContentText>
          Be careful. You will sign in as another user and will be able to see
          and edit their data
        </DialogContentText>
        <FormControl component="fieldset">
          <FormLabel component="legend">Search by:</FormLabel>
          <RadioGroup
            row
            aria-label="search"
            name="searchby"
            value={searchBy}
            onChange={handleSearchBy}
          >
            <FormControlLabel
              value="displayName"
              control={<Radio color="primary" />}
              label="Display name"
            />
            {/* <FormControlLabel
              value="email"
              control={<Radio color="primary" />}
              label="Email"
            /> */}
            <FormControlLabel
              value="id"
              control={<Radio color="primary" />}
              label="Id"
            />
          </RadioGroup>
        </FormControl>
        <div styleName="row">
          <Autocomplete
            styleName="autocomplete"
            autoHighlight
            classes={{
              popper: "SignInAsUserDialog__popper",
              paper: "SignInAsUserDialog__paper",
              option: "SignInAsUserDialog__option"
            }}
            disablePortal
            forcePopupIcon={false}
            fullWidth
            getOptionLabel={option =>
              option[searchBy === "id" ? "value" : "label"]
            }
            getOptionSelected={option => (value || {}).value === option.value}
            inputValue={query}
            noOptionsText={
              loading ? (
                intl.messages["loading"] || "Loading..."
              ) : (
                <span>
                  {intl.messages["comments.notFound"] ||
                    "No one found matching"}{" "}
                  <span styleName="search-text">{query}</span>
                </span>
              )
            }
            onChange={(e, newValue) => setValue(newValue)}
            onInputChange={(e, newValue) => setQuery(newValue)}
            open={true}
            options={options}
            PaperComponent="div"
            PopperComponent="div"
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  intl.messages["comments.searchPrompt"] || "Search users"
                }
                autoFocus
              />
            )}
            size="small"
            value={value}
          />
          <Button
            style={{ height: 40 }}
            color="primary"
            variant="contained"
            disabled={!value}
            onClick={handleConfirmWithValue}
          >
            {intl.messages["comments.searchButton"] || "Go"}
          </Button>
        </div>
      </DialogContent>
    </>
  );
};

SignInAsUserDialog.propTypes = propTypes;

const SignInAsUserDialogWrapper = props => (
  <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
    <SignInAsUserDialog {...props} />
  </Dialog>
);

SignInAsUserDialogWrapper.propTypes = propTypes;

export default SignInAsUserDialogWrapper;
