// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignInToCompaniesDialog__row{position:relative;display:flex}.SignInToCompaniesDialog__helper-text{display:flex;align-items:center;white-space:nowrap}.SignInToCompaniesDialog__autocomplete{margin-right:16px}.SignInToCompaniesDialog__popper{top:64px;width:100% !important}.SignInToCompaniesDialog__paper{height:calc(467px - 16px - 64px);overflow:auto !important}.SignInToCompaniesDialog__icon-button{position:absolute;top:8px;right:8px;color:inherit}.SignInToCompaniesDialog__option{border-bottom:1px solid #cfd8dc}.SignInToCompaniesDialog__option:first-child{border-top:1px solid #cfd8dc}.SignInToCompaniesDialog__search-text{color:#37474f;font-weight:600}\n", ""]);
// Exports
exports.locals = {
	"row": "SignInToCompaniesDialog__row",
	"helper-text": "SignInToCompaniesDialog__helper-text",
	"autocomplete": "SignInToCompaniesDialog__autocomplete",
	"popper": "SignInToCompaniesDialog__popper",
	"paper": "SignInToCompaniesDialog__paper",
	"icon-button": "SignInToCompaniesDialog__icon-button",
	"option": "SignInToCompaniesDialog__option",
	"search-text": "SignInToCompaniesDialog__search-text"
};
module.exports = exports;
