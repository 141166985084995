import React from "react";
import { SITE_CONFIG } from "../../config.js";
import { oneOfType, arrayOf, element } from "prop-types";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { MuiThemeProvider as V0MuiThemeProvider } from "material-ui";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import {
  grey50,
  blueGrey300,
  blueGrey200,
  blueGrey100,
  blueGrey50,
  orange500,
  red500,
  blueGrey800,
  blueGrey600,
  cyan400
} from "material-ui/styles/colors";
// import { blueGrey, cyan, red } from "@material-ui/core/colors";
// console.log(SITE_CONFIG)

const theme = createMuiTheme({
  palette: {
    primary: { main: SITE_CONFIG.primaryColor },
    secondary: { main: SITE_CONFIG.secondaryColor },
    error: { main: SITE_CONFIG.errorColor }
  },
  fontFamily: "Roboto, sans-serif",
  fontSize: 12
});

const themeV0 = getMuiTheme({
  palette: {
    primary1Color: blueGrey800,
    primary2Color: cyan400,
    primary3Color: blueGrey100,
    primary4Color: blueGrey50,
    canvasColor: grey50,
    accent1Color: orange500,
    // accent2Color: orange400, // don't use this, it is what material-ui uses for hoverColor in Tables
    redColor: red500,
    primaryLtColor: blueGrey600
  },
  fontFamily: "Roboto, sans-serif",
  fontSize: 12
});

const propTypes = {
  children: oneOfType([element, arrayOf(element)])
};

const ThemeProvider = ({ children }) => {
  // console.log(SITE_CONFIG);

  return (
    <MuiThemeProvider theme={theme}>
      <V0MuiThemeProvider muiTheme={themeV0}>{children}</V0MuiThemeProvider>
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = propTypes;

export default ThemeProvider;
