import React, { useState } from "react";
import { object } from "prop-types";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { Button, Tooltip } from "@material-ui/core";

import SignInToCompaniesDialog from "../SignInToCompaniesDialog/SignInToCompaniesDialog.jsx";
import GET_COMPANIES from "../../../../queries/GET_COMPANIES.graphql";
import "./CompanyMenu.scss";

const propTypes = {
  user: object
};

const CompanyMenu = ({ user }) => {
  const [companiesDialogOpen, setCompaniesDialogOpen] = useState(false);
  const { data, error, loading } = useQuery(GET_COMPANIES);
  const companies = _.get(data, "companies.edges", []);
  if (error) return `Error getting companies`;

  const handleSignInToCompaniesToggle = () => {
    setCompaniesDialogOpen(prev => !prev);
  };

  // Hide CompanyMenu if loading or only can view one.
  if (loading || companies.length < 2) {
    return null;
  }

  const companiesNames = user.companies.edges.map(
    ({ company }) => company.name
  );

  const styles = {
    button: {
      color: "#fff",
      textTransform: "capitalize",
      fontWeight: 400
    }
  };

  return (
    <>
      <Button style={styles.button} onClick={handleSignInToCompaniesToggle}>
        <span styleName="name">{companiesNames[0]}</span>
        {companiesNames.length > 1 && (
          <Tooltip title={companiesNames.slice(1).join(", ")}>
            <div styleName="number">+{companiesNames.length - 1}</div>
          </Tooltip>
        )}
      </Button>
      <SignInToCompaniesDialog
        open={companiesDialogOpen}
        user={user}
        onClose={handleSignInToCompaniesToggle}
      />
    </>
  );
};

CompanyMenu.propTypes = propTypes;

export default CompanyMenu;
