import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebaseui from "firebaseui";
import firebase from "firebase/app";
import "firebase/auth";
import { FIREBASE_CONFIG } from "../config.js";

firebase.initializeApp(FIREBASE_CONFIG);

const FirebaseProviderSignIn = () => {
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "redirect",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/",
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    immediateFederatedRedirect: false,

    // We will display Google and Facebook as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        window.sessionStorage.token = authResult.credential.idToken;
        return true;
      }
    }
  };

  return (
    <div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
};

const FirebaseGetToken = () => {
  return firebase.auth().currentUser.getIdToken(true);
};

const FirebaseCreateUser = (email, password) => {
  console.log(email);
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

const FirebaseEmailPasswordSignIn = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

const FirebaseSignOut = () => {
  firebase
    .auth()
    .signOut()
    .then(function() {
      // Sign-out successful.
    })
    .catch(function(error) {
      // An error happened.
    });
};

const FirebaseSendLink = (email, settings) => {
  return firebase.auth().sendSignInLinkToEmail(email, settings);
};

const FirebaseMakeCredentials = (email, password) => {
  return firebase.auth.EmailAuthProvider.credential(email, password);
};

const FirebaseSignInWithLink = email => {
  return firebase.auth().signInWithEmailLink(email, window.location.href);
};

const FirebaseLinkWithCredentials = async credential => {
  // console.log(firebase.auth.EmailAuthProvider)

  await firebase
    .auth()
    .currentUser.unlink(firebase.auth.EmailAuthProvider.PROVIDER_ID);

  return firebase.auth().currentUser.linkWithCredential(credential);
};

const VerifyOobCode = code => {
  return firebase.auth().verifyPasswordResetCode(code);
};

const FirebaseResetPassword = (code, password) => {
  firebase
    .auth()
    .confirmPasswordReset(code, password)
    .then(resp => {
      console.log("success");
      console.log(resp);
    })
    .catch(err => {
      console.log("failed");
      console.log(err);
    });
};

export {
  FirebaseProviderSignIn,
  FirebaseEmailPasswordSignIn,
  FirebaseSignOut,
  VerifyOobCode,
  FirebaseResetPassword,
  FirebaseGetToken,
  FirebaseCreateUser,
  FirebaseSendLink,
  FirebaseMakeCredentials,
  FirebaseSignInWithLink,
  FirebaseLinkWithCredentials
};
