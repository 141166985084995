import React, { Suspense, lazy, useEffect } from "react";
import { object } from "prop-types";
import { withRouter, Route, Redirect, Switch } from "react-router-dom";
import _ from "lodash";
import { SITE_CONFIG } from "../../config.js";

import Header from "./Header/Header";
import Loading from "../Loading/Loading.jsx";
import { useUser } from "../../hooks/useUser";
import "./Main.scss";

// Routes
const Inbox = lazy(() => import("./Inbox/Inbox"));
const Library = lazy(() => import("./Library/Library.jsx"));
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const Agents = lazy(() => import("./Agents/Agents.jsx"));
const CallRandomizer = lazy(() =>
  import("./CallRandomizer/CallRandomizer.jsx")
);
const Discover = lazy(() => import("./Discover/Discover.jsx"));
const Settings = lazy(() => import("./Settings/Settings.jsx"));
// const Scripts = lazy(() => import("./Scripts/Scripts.jsx"));
const PageAuthorization = lazy(() =>
  import("./PageAuthorization/PageAuthorization")
);
const Onboarding = lazy(() => import("../Onboarding/Onboarding.jsx"));

const propTypes = {
  location: object,
  history: object
};

const Main = ({ location, history }) => {
  // CONTEXT

  const { user } = useUser();
  // console.log(user)

  // HEAP Analytics properties
  let companyProps = {};
  for (const [index, edge] of _.get(user, "companies.edges", {}).entries()) {
    const companyIndex = (index + 1).toString();
    let integrations = {};
    for (const integration of Object.keys(
      _.get(edge, "company.integrationsSettings", {})
    )) {
      // console.log(integration)
      // console.log(_.get(edge, 'company.integrationsSettings', {})[integration]['status'])
      integrations["Company" + companyIndex + integration] = _.get(
        edge,
        "company.integrationsSettings",
        {}
      )[integration]["status"];
    }
    let set = {};
    if (_.isEmpty(integrations)) {
      set = { ["Company" + companyIndex + "IntegrationsSet"]: false };
    } else {
      set = { ["Company" + companyIndex + "IntegrationsSet"]: true };
    }

    const newProps = {
      ["Company" + companyIndex + "Name"]: edge.company.name,
      ["Company" + companyIndex + "Id"]: edge.company.id,
      ...integrations,
      ...set
    };
    companyProps = { ...companyProps, ...newProps };
  }
  // console.log(companyProps)

  heap.addUserProperties({
    Name: user.name,
    Role: user.role,
    email: user.email,
    ...companyProps
  });
  // heap.identify(user.id)
  // EFFECTS

  useEffect(() => {
    document.title = `${SITE_CONFIG.contentName} - ${location.pathname.slice(
      1
    )}`;
  }, [location.pathname]);

  // const {
  //   data: { callTypes, categories },
  //   loading,
  //   error
  // } = useQuery(GET_CALL_TYPES_AND_CATEGORIES);
  // if (loading) return <div>Loading...</div>;
  // if (error) return `Error! ${error.message}`;

  const handlePathChange = (path, state = {}) => {
    history.push({
      pathname: path,
      state: {
        ...state
      }
    });
  };

  let newProps = {
    userInfo: user,
    callTypes: [], // callTypes || [],
    callTypesObj: {}, // _.keyBy(callTypes, "id"),
    allCategories: [] // categories || []
  };

  const isAgent = user.role === "BASIC";

  // const homePath = isAgent ? `agents/${user.id}` : "dashboard";
  const homePath = "dashboard";

  return (
    <div styleName="container">
      <Header user={user} location={location} onPathChange={handlePathChange} />
      <div styleName="content">
        <Suspense fallback={<Loading overlay />}>
          <Switch>
            <Route path="/" exact render={() => <Redirect to={homePath} />} />
            {routes.map(route => {
              const Page = route.component;

              return isAgent && !route.agentCanView
                ? route.visible && (
                    <Route
                      key={route.path}
                      path={route.path}
                      render={props => (
                        <PageAuthorization
                          {...props}
                          pathToTitle={route.path}
                        />
                      )}
                    />
                  )
                : route.visible && (
                    <Route
                      key={route.path}
                      path={route.path}
                      render={props => <Page {...props} {...newProps} />}
                    />
                  );
            })}
            <Route path="/" render={() => <Redirect to={homePath} />} />
          </Switch>
        </Suspense>
      </div>
    </div>
  );
};

Main.propTypes = propTypes;

export default withRouter(Main);

const routes = [
  {
    path: "/agents",
    component: Agents,
    agentCanView: true,
    visible: SITE_CONFIG.Scoring.AgentTable.route
  },
  {
    path: "/call-randomizer",
    component: CallRandomizer,
    visible: SITE_CONFIG.Scoring.Randomizer.route
  },
  {
    path: "/discover",
    component: Discover,
    visible: SITE_CONFIG.Discover.route
  },
  {
    path: "/dashboard",
    component: Dashboard,
    agentCanView: true,
    visible: SITE_CONFIG.Dashboard.route
  },
  {
    path: "/notifications",
    component: Inbox,
    agentCanView: true,
    visible: SITE_CONFIG.Inbox.route
  },
  {
    path: "/library",
    component: Library,
    agentCanView: true,
    visible: SITE_CONFIG.Library.route
  },
  {
    path: "/settings",
    component: Settings,
    agentCanView: true,
    visible: SITE_CONFIG.Settings.route
  },
  {
    path: "/getting-started",
    component: Onboarding,
    visible: SITE_CONFIG.Onboarding.route
  }
  // {
  //   path: "/scripts",
  //   component: Scripts,
  //   visible: true
  // }
];
