import React, { useState } from "react";
import { bool, func, object } from "prop-types";
import _ from "lodash";
import {
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/react-hooks";

import Dialog from "../../../Dialog.jsx";
import GET_COMPANIES_BY_PREFIX from "../../../../queries/GET_COMPANIES_BY_PREFIX.graphql";
import SIGN_IN_TO from "../../../../queries/mutations/SIGN_IN_TO.graphql";
import "./SignInToCompaniesDialog.scss";

const propTypes = {
  open: bool,
  user: object,
  onClose: func
};

const SignInToCompaniesDialog = ({ user, onClose }) => {
  const isSuperuser = user.role === "SUPERUSER";
  const intl = useIntl();
  const [value, setValue] = useState(isSuperuser ? [] : null);
  //   isSuperuser
  //     ? user.companies.edges.map(({ company }) => ({
  //         value: company.id,
  //         label: company.name
  //       }))
  //     : user.companies.edges.map(({ company }) => ({
  //         value: company.id,
  //         label: company.name
  //       }))[0]
  // );
  const [query, setQuery] = useState("");

  // QUERY

  const companyOptions = {
    variables: {
      prefix: query,
      include: isSuperuser
        ? value.map(val => val.value)
        : value
        ? [value.value]
        : [],
      size: 10
    }
  };
  const { data, loading } = useQuery(GET_COMPANIES_BY_PREFIX, companyOptions);

  const companiesByName = _.get(data, "companiesByName.edges", []);
  const companiesById = _.get(data, "companiesById.edges", []);
  const allCompanies = [...companiesByName, ...companiesById].map(
    ({ company }) => ({
      value: company.id,
      label: company.name
    })
  );
  const options = _.uniqBy(allCompanies, company => company.value);

  const [signInTo] = useMutation(SIGN_IN_TO);

  const handleConfirmWithValue = async () => {
    try {
      const { data } = await signInTo({
        variables: {
          companies: isSuperuser ? value.map(val => val.value) : [value.value]
        }
      });
      window.sessionStorage.token = data.signInTo.token;
      window.sessionStorage.tokenExpiresAt = data.signInTo.expiresAt;
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Sign In To Companies</DialogTitle>
      <IconButton
        style={{ position: "absolute" }}
        styleName="icon-button"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ height: 467 }}>
        <div styleName="row">
          <Autocomplete
            styleName="autocomplete"
            autoHighlight
            classes={{
              popper: "SignInToCompaniesDialog__popper",
              paper: "SignInToCompaniesDialog__paper",
              option: "SignInToCompaniesDialog__option"
            }}
            disablePortal
            filterSelectedOptions={isSuperuser}
            forcePopupIcon={false}
            fullWidth
            getOptionLabel={option => option.label}
            getOptionSelected={option =>
              isSuperuser
                ? !!value.find(val => val.value === option.value)
                : value?.value === option.value
            }
            inputValue={query}
            multiple={isSuperuser}
            noOptionsText={
              loading ? (
                intl.messages["loading"] || "Loading..."
              ) : (
                <span>
                  {intl.messages["comments.notFound"] || "None found matching"}{" "}
                  <span styleName="search-text">{query}</span>
                </span>
              )
            }
            onChange={(e, newValue) => setValue(newValue)}
            onInputChange={(e, newValue) => setQuery(newValue)}
            open={true}
            options={options}
            PaperComponent="div"
            PopperComponent="div"
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  intl.messages["comments.searchPrompt"] || "Search companies"
                }
                autoFocus
              />
            )}
            size="small"
            value={value}
          />
          <Button
            style={{ height: 40 }}
            color="primary"
            variant="contained"
            disabled={isSuperuser ? value.length === 0 : !value}
            onClick={handleConfirmWithValue}
          >
            {intl.messages["comments.searchButton"] || "Go"}
          </Button>
        </div>
      </DialogContent>
    </>
  );
};

SignInToCompaniesDialog.propTypes = propTypes;

const SignInToCompaniesDialogWrapper = props => (
  <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
    <SignInToCompaniesDialog {...props} />
  </Dialog>
);

SignInToCompaniesDialogWrapper.propTypes = propTypes;

export default SignInToCompaniesDialogWrapper;
