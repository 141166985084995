// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CompanyMenu__select-container{margin:0 8px;padding:0 8px;color:#fff !important}.CompanyMenu__icon{color:inherit !important}.CompanyMenu__select:focus{background-color:transparent}.CompanyMenu__name{margin:0 8px;font-size:16px}.CompanyMenu__number{padding:1px 2px;border-radius:2px;color:#37474f;background:#FFFFFF;font-size:14px;font-weight:600;line-height:14px}\n", ""]);
// Exports
exports.locals = {
	"select-container": "CompanyMenu__select-container",
	"icon": "CompanyMenu__icon",
	"select": "CompanyMenu__select",
	"name": "CompanyMenu__name",
	"number": "CompanyMenu__number"
};
module.exports = exports;
