import React, { useState, useEffect } from "react";
import { SITE_CONFIG } from "../../../config.js";
import { object } from "prop-types";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import { Paper, TextField, Button } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useIntl, FormattedMessage } from "react-intl";

// import LogoImg from "../../../../img/logo.png";
import SIGN_IN_WITH_PASSWORD from "../../../queries/mutations/SIGN_IN_WITH_PASSWORD.graphql";
import useInput from "../../../hooks/useFormInput";
import "./Login.scss";
// import {
//   FirebaseGetToken,
//   FirebaseEmailPasswordSignIn
// } from "../../../utils/firebase.jsx";

const propTypes = {
  location: object,
  history: object
};

const Login = ({ location, history }) => {
  // REFS
  const formRef = React.useRef();

  // STATE

  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inactivityAlertOpen, setInactivityAlertOpen] = useState(false);
  const [shrink, setShrink] = useState(undefined);
  const [email, emailDispatch, emailError] = useInput("email", "");
  const [password, passwordDispatch, passwordError] = useInput("password", "");
  const [attempts, setAttempts] = useState(0);
  // console.log(attempts)
  const intl = useIntl();
  // EFFECTS

  useEffect(() => {
    if (_.get(location, "state.sessionExpired")) {
      setInactivityAlertOpen(true);
    }
  }, [location]);

  // fix for material-ui bug that doesn't shrink label with chrome's autofill
  useEffect(() => {
    function doIt(e) {
      if (e.animationName === "App__onAutoFillStart") {
        const el = document.querySelector("input:-webkit-autofill");
        if (el) {
          setShrink(true);
        }
      }
    }
    document.addEventListener("animationstart", doIt);
    return () => {
      document.removeEventListener("animationstart", doIt);
    };
  }, []);

  // MUTATIONS

  const [signInWithPassword] = useMutation(SIGN_IN_WITH_PASSWORD);

  // HANDLES

  const handleSubmit = e => {
    e.preventDefault();
    if (errorMessage) {
      setErrorMessage("");
    }
    setSubmitted(true);
    if (formRef.current.checkValidity()) {
      handleLogin();
    }
  };

  const handleTexfieldKeyDown = event => {
    if (event.keyCode == 13) {
      handleSubmit(event);
    }
  };

  const handleLogin = async () => {
    setAttempts(old => old + 1);
    window.sessionStorage.token = "";
    try {
      const { data } = await signInWithPassword({
        variables: { email, password }
      });

      const { token, expiresAt } = data.signInWithPassword;
      window.sessionStorage.token = token;
      window.sessionStorage.tokenExpiresAt = expiresAt;

      if (
        _.get(location, "state.nextPathname") &&
        _.get(location, "state.nextPathname") !== "/login"
      ) {
        // console.log("going to " + location.state.nextPathname);
        history.push({
          pathname: location.state.nextPathname,
          search: location.state.nextSearch || null
        });
      } else {
        history.push("/");
      }
    } catch (error) {
      // console.error(error);
      attempts > 2
        ? setErrorMessage(
            "Error: Your email or password may be incorrect.\nTry resetting your password."
          )
        : setErrorMessage("Error: Your email or password may be incorrect.");
    }
  };

  const styles = {
    paper: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: 20,
      width: 400
    },
    closeIcon: {
      position: "absolute",
      top: 11,
      right: 16,
      cursor: "pointer"
    }
  };

  const maintenanceMode = false;
  return (
    <>
      {inactivityAlertOpen && (
        <div styleName="alert">
          <FormattedMessage
            id="timeout"
            defaultMessage="You have been logged out due to inactivity."
          />
          <CloseIcon
            style={styles.closeIcon}
            onClick={() => setInactivityAlertOpen(false)}
            color="inherit"
          />
        </div>
      )}
      <Paper elevation={2} style={styles.paper}>
        <div>
          <img src={SITE_CONFIG.logo} width="200" styleName="logo" />
        </div>
        {maintenanceMode && (
          <div style={{ margin: "30px 15px", lineHeight: "24px" }}>
            {
              <FormattedMessage
                id="maintenance"
                defaultMessage="Voxjar is currently unavailable while we perform scheduled upgrades
            and maintenance. Thank you for your patience."
              />
            }
          </div>
        )}
        {!maintenanceMode && (
          <>
            <h3 styleName="title">
              <FormattedMessage
                id="login.title"
                defaultMessage="Sign in to your account"
              />
            </h3>
            {errorMessage && <p styleName="error">{errorMessage}</p>}
            <form ref={formRef} onSubmit={handleSubmit} noValidate>
              <TextField
                style={{ marginTop: 24 }}
                id="email"
                name="email"
                label={intl.messages["login.email"] || "Email"}
                type="email"
                placeholder={
                  intl.messages["login.emailPrompt"] || "Enter email"
                }
                value={email}
                onChange={e =>
                  emailDispatch({ type: "CHANGE", value: e.target.value })
                }
                inputProps={{ onKeyDown: handleTexfieldKeyDown }}
                InputLabelProps={{ shrink }}
                helperText={submitted && emailError}
                error={submitted && !!emailError}
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                style={{ marginTop: 24 }}
                id="password"
                label={
                  <FormattedMessage
                    id="login.password"
                    defaultMessage="Password"
                  />
                }
                type="password"
                placeholder={
                  intl.messages["login.passwordPrompt"] || "Enter password"
                }
                value={password}
                onChange={e =>
                  passwordDispatch({ type: "CHANGE", value: e.target.value })
                }
                inputProps={{ onKeyDown: handleTexfieldKeyDown }}
                InputLabelProps={{ shrink }}
                helperText={submitted && passwordError}
                error={submitted && !!passwordError}
                variant="outlined"
                fullWidth
                required
              />
              {SITE_CONFIG.ResetPassword.route ? (
                <p styleName="forgot">
                  <FormattedMessage
                    id="login.forgot"
                    defaultMessage="Forgot Your Password?"
                  />{" "}
                  <Link
                    to={{
                      pathname: `/reset-request`,
                      state: { email }
                    }}
                    styleName="reset-link"
                  >
                    <FormattedMessage id="login.reset" defaultMessage="Reset" />
                  </Link>
                </p>
              ) : (
                <p styleName="forgot"></p>
              )}

              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                <FormattedMessage id="login.button" defaultMessage="Login" />
              </Button>
            </form>
            {SITE_CONFIG.CreateAccount.route && (
              <p styleName="post-info">
                <FormattedMessage
                  id="login.noAccount"
                  defaultMessage="Don’t have an account?"
                />{" "}
                <Link to="/create-account" styleName="reset-link">
                  <FormattedMessage
                    id="login.create"
                    defaultMessage="Create an account"
                  />
                </Link>
              </p>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

Login.propTypes = propTypes;

export default Login;
