/**
 * Authorization module.
 * @module schema/authorization
 */

/**
 * Verify that Requester is authorized to perform privileges.
 * @param {Object} user The User making the request.
 * @param {String[]} privileges The privileges to verify.
 * @return {Bool} Whether or not the Requester is authorized.
 */
const privilegeAssignments = {
  // give a value of the highest role that applies; if also a developer role, add DEVELOPER in array

  IMPERSONATE_USER: "SUPERUSER",
  USE_DEVELOPER_MODE: ["SUPERUSER", "DEVELOPER"],
  GRANT_IMPERSONATE_USER: "SUPERUSER",
  GRANT_USE_DEVELOPER_MODE: "SUPERUSER",

  READ_OTHERS_COMPANIES: "SUPERUSER",
  READ_OWN_COMPANIES: ["AGENT", "READ_ONLY"],
  UPDATE_OTHERS_COMPANIES: "SUPERUSER",
  UPDATE_OWN_COMPANIES: "ADMIN",
  DELETE_OTHERS_COMPANIES: "SUPERUSER",
  DELETE_OWN_COMPANIES: "SUPERUSER",
  GRANT_READ_OTHERS_COMPANIES: "SUPERUSER",
  GRANT_READ_OWN_COMPANIES: "MANAGER",
  GRANT_UPDATE_OTHERS_COMPANIES: "SUPERUSER",
  GRANT_UPDATE_OWN_COMPANIES: "ADMIN",
  GRANT_DELETE_OTHERS_COMPANIES: "SUPERUSER",
  GRANT_DELETE_OWN_COMPANIES: "SUPERUSER",

  READ_OTHERS_CALLS: ["AGENT", "READ_ONLY"],
  READ_OWN_CALLS: ["AGENT", "READ_ONLY"],
  UPDATE_OTHERS_CALLS: "MANAGER",
  UPDATE_OWN_CALLS: "AGENT",
  DELETE_OTHERS_CALLS: "SUPERUSER",
  DELETE_OWN_CALLS: "SUPERUSER",
  GRANT_READ_OTHERS_CALLS: "ADMIN",
  GRANT_READ_OWN_CALLS: "MANAGER",
  GRANT_UPDATE_OTHERS_CALLS: "ADMIN",
  GRANT_UPDATE_OWN_CALLS: "MANAGER",
  GRANT_DELETE_OTHERS_CALLS: "SUPERUSER",
  GRANT_DELETE_OWN_CALLS: "SUPERUSER",

  READ_OTHERS_SCORES: ["AGENT", "READ_ONLY"],
  READ_OWN_SCORES: ["AGENT", "READ_ONLY"],
  UPDATE_OTHERS_SCORES: "MANAGER",
  UPDATE_OWN_SCORES: "AGENT",
  DELETE_OTHERS_SCORES: "SUPERUSER",
  DELETE_OWN_SCORES: "SUPERUSER",
  GRANT_READ_OTHERS_SCORES: "ADMIN",
  GRANT_READ_OWN_SCORES: "MANAGER",
  GRANT_UPDATE_OTHERS_SCORES: "ADMIN",
  GRANT_UPDATE_OWN_SCORES: "MANAGER",
  GRANT_DELETE_OTHERS_SCORES: "SUPERUSER",
  GRANT_DELETE_OWN_SCORES: "SUPERUSER",

  READ_OTHERS_USERS: ["AGENT", "READ_ONLY"],
  READ_OWN_USERS: ["AGENT", "READ_ONLY"],
  UPDATE_OTHERS_USERS: "MANAGER",
  UPDATE_OWN_USERS: ["AGENT", "READ_ONLY"],
  DELETE_OTHERS_USERS: "SUPERUSER",
  DELETE_OWN_USERS: "SUPERUSER",
  GRANT_READ_OTHERS_USERS: "ADMIN",
  GRANT_READ_OWN_USERS: "MANAGER",
  GRANT_UPDATE_OTHERS_USERS: "ADMIN",
  GRANT_UPDATE_OWN_USERS: "MANAGER",
  GRANT_DELETE_OTHERS_USERS: "SUPERUSER",
  GRANT_DELETE_OWN_USERS: "SUPERUSER",

  UPDATE_OTHERS_USERS_COMPANY: "SUPERUSER",
  UPDATE_OWN_USERS_COMPANY: "SUPERUSER",
  GRANT_UPDATE_OTHERS_USERS_COMPANY: "SUPERUSER",
  GRANT_UPDATE_OWN_USERS_COMPANY: "SUPERUSER",

  READ_PERSONALLY_IDENTIFIABLE_INFORMATION: ["ADMIN", "READ_ONLY"],
  GRANT_READ_PERSONALLY_IDENTIFIABLE_INFORMATION: "ADMIN",

  READ_OWN_INTEGRATIONS: ["ADMIN", "READ_ONLY"],
  UPDATE_OWN_INTEGRATIONS: "ADMIN",
  DELETE_OWN_INTEGRATIONS: "ADMIN",
  GRANT_READ_OWN_INTEGRATIONS: "ADMIN",
  GRANT_UPDATE_OWN_INTEGRATIONS: "ADMIN",
  GRANT_DELETE_OWN_INTEGRATIONS: "ADMIN",

  READ_OWN_METRICS: ["AGENT", "READ_ONLY"],
  UPDATE_OWN_METRICS: "MANAGER",
  DELETE_OWN_METRICS: "MANAGER",
  GRANT_READ_OWN_METRICS: "MANAGER",
  GRANT_UPDATE_OWN_METRICS: "ADMIN",
  GRANT_DELETE_OWN_METRICS: "ADMIN",

  READ_OWN_TEAMS: ["MANAGER", "READ_ONLY"],
  UPDATE_OWN_TEAMS: "MANAGER",
  DELETE_OWN_TEAMS: "MANAGER",
  GRANT_READ_OWN_TEAMS: "ADMIN",
  GRANT_UPDATE_OWN_TEAMS: "ADMIN",
  GRANT_DELETE_OWN_TEAMS: "ADMIN",

  READ_OTHERS_NOTES: ["AGENT", "READ_ONLY"],
  READ_OWN_NOTES: ["AGENT", "READ_ONLY"],
  UPDATE_OTHERS_NOTES: "SUPERUSER",
  UPDATE_OWN_NOTES: "AGENT",
  DELETE_OTHERS_NOTES: "SUPERUSER",
  DELETE_OWN_NOTES: "AGENT",
  GRANT_READ_OTHERS_NOTES: "MANAGER",
  GRANT_READ_OWN_NOTES: "MANAGER",
  GRANT_UPDATE_OTHERS_NOTES: "SUPERUSER",
  GRANT_UPDATE_OWN_NOTES: "MANAGER",
  GRANT_DELETE_OTHERS_NOTES: "SUPERUSER",
  GRANT_DELETE_OWN_NOTES: "MANAGER",

  SEND_EMAIL: "SUPERUSER",
  GRANT_SEND_EMAIL: "SUPERUSER"
};

let psuedoRoles = {};
for (const privs in privilegeAssignments) {
  if (Array.isArray(privilegeAssignments[privs])) {
    for (const role of privilegeAssignments[privs]) {
      if (!psuedoRoles[role]) {
        psuedoRoles[role] = [];
      }
      psuedoRoles[role].push(privs);
    }
  } else {
    if (!psuedoRoles[privilegeAssignments[privs]]) {
      psuedoRoles[privilegeAssignments[privs]] = [];
    }
    psuedoRoles[privilegeAssignments[privs]].push(privs);
  }
}
// console.log(psuedoRoles)
const roles = ["GUEST", "AGENT", "MANAGER", "ADMIN", "SUPERUSER"];
function atLeast(user, role) {
  switch (role) {
    case "GUEST":
      return roles.indexOf(user.role) > -1;
    case "AGENT":
      return roles.indexOf(user.role) > 0;
    case "MANAGER":
      return roles.indexOf(user.role) > 1;
    case "ADMIN":
      return roles.indexOf(user.role) > 2;
    case "SUPERUSER":
      return roles.indexOf(user.role) > 3;
    default:
      return false;
  }
}

function can(user, privileges) {
  return true;
  // if (!user.privileges) return false;
  // return !privileges.some(p => user.privileges.indexOf(p) === -1);
}

function isRole(user, role) {
  return user.role.replace("_", "") === role;
  // return user.privileges.indexOf(role) > -1;
}

function getPsuedoRole(privileges) {
  return "SUPERUSER";
  // let role = "";
  // if (privileges.some(p => p === "IMPERSONATE_USER")) {
  //   role = "SUPERUSER";
  // } else if (
  //   privileges.some(
  //     p =>
  //       (privilegeAssignments[p] || "").toString().indexOf("DEVELOPER") !== -1
  //   )
  // ) {
  //   role = "DEVELOPER";
  // } else if (
  //   privileges.every(
  //     p => (privilegeAssignments[p] || "").toString().indexOf("READ_ONLY") > -1
  //   )
  // ) {
  //   role = "READ_ONLY";
  // } else if (
  //   privileges.some(
  //     p => (privilegeAssignments[p] || "").toString().indexOf("ADMIN") !== -1
  //   )
  // ) {
  //   role = "ADMIN";
  // } else if (
  //   privileges.some(
  //     p => (privilegeAssignments[p] || "").toString().indexOf("MANAGER") !== -1
  //   )
  // ) {
  //   role = "MANAGER";
  // } else if (
  //   privileges.some(
  //     p => (privilegeAssignments[p] || "").toString().indexOf("AGENT") !== -1
  //   )
  // ) {
  //   role = "AGENT";
  // }

  // return role;
}

function getDefaultPrivileges(psuedoRole) {
  let privileges = [];
  switch (psuedoRole) {
    case "READ_ONLY":
      privileges = privileges.concat(psuedoRoles.READ_ONLY);
      break;
    case "SUPERUSER":
      privileges = privileges.concat(psuedoRoles.SUPERUSER);
    case "DEVELOPER":
      privileges = privileges.concat(psuedoRoles.DEVELOPER);
    case "ADMIN":
      privileges = privileges.concat(psuedoRoles.ADMIN);
    case "MANAGER":
      privileges = privileges.concat(psuedoRoles.MANAGER);
    case "AGENT":
      privileges = privileges.concat(psuedoRoles.AGENT);
      break;
    default:
      privileges = [];
      break;
  }
  return privileges;
}

// can(user, translationTo(psuedo))

//     if (!authorization.can(user, ['READ_OTHERS_COMPANIES'])) {
//         throw new Error('Insufficient privileges for requested action.');
//     }

export default { can, getDefaultPrivileges, getPsuedoRole, isRole, atLeast };
