import React from "react";
import { object } from "prop-types";
import { Paper, Button } from "@material-ui/core";
import { SITE_CONFIG } from "../../../config.js";
import { FormattedMessage } from "react-intl";

// import LogoImg from "../../../../img/logo.png";
import "./ResetRequestSent.scss";

const propTypes = {
  history: object
};

const ResetRequestSent = ({ history }) => {
  const handleToLogin = () => {
    history.push("/login");
  };

  const styles = {
    paper: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: 20,
      width: 400
    }
  };
  return (
    <Paper elevation={2} style={styles.paper}>
      <div>
        <img src={SITE_CONFIG.logo} width="200" styleName="logo" />
      </div>
      <h3 styleName="title">
        <FormattedMessage id="resetsent.title" defaultMessage="Nice job!" />
      </h3>
      <p styleName="content">
        <FormattedMessage
          id="resetsent.message"
          defaultMessage="Check your email for the reset link."
        />
      </p>
      <Button
        color="primary"
        variant="contained"
        onClick={handleToLogin}
        fullWidth
      >
        <FormattedMessage
          id="resetsent.button"
          defaultMessage="Back to login"
        />
      </Button>
    </Paper>
  );
};

ResetRequestSent.propTypes = propTypes;

export default ResetRequestSent;
