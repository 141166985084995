// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Main__container{height:100%}.Main__content{display:flex;flex-direction:column;margin:0;padding-top:56px;padding-bottom:8px;height:100%;transition:margin 0.3s ease 0s;z-index:0}\n", ""]);
// Exports
exports.locals = {
	"container": "Main__container",
	"content": "Main__content"
};
module.exports = exports;
