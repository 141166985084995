import moment from "moment";

function getTimeElements(duration) {
  const milliseconds = moment.duration(duration).asMilliseconds();
  const ms = parseInt(milliseconds / 1000, 10);
  let hours = Math.floor(ms / 3600);
  let minutes = Math.floor((ms - hours * 3600) / 60);
  let seconds = ms - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return { hours, minutes, seconds };
}

// args can be in milliseconds or as a duration
function formatTime(duration, maxDuration = 0) {
  const { hours, minutes, seconds } = getTimeElements(+duration || 0);

  if (
    Math.floor(moment.duration(maxDuration).asMilliseconds() / 1000 / 3600) >
      0 ||
    hours > 0
  ) {
    return hours + ":" + minutes + ":" + seconds;
  } else {
    return minutes + ":" + seconds;
  }
}

export { getTimeElements, formatTime };
