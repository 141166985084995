import React, { useEffect, useState } from "react";
import { object, number, bool, string } from "prop-types";
import cx from "classnames";
// import CircularProgress from "@material-ui/core/CircularProgress";
import loadingImg from "../../../img/voxjar_loading.gif";

import "./Loading.scss";

const propTypes = {
  className: string,
  style: object,
  size: number,
  overlay: bool,
  greyOverlay: bool,
  message: string,
  delay: number
};

const Loading = ({
  className,
  style,
  size = 30,
  overlay,
  greyOverlay,
  message,
  delay = 2000
}) => {
  // STATE

  const [showMessage, setShowMessage] = useState(false);

  // EFFECTS

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <div
      className={className}
      styleName={cx("container", {
        overlay: overlay,
        greyoverlay: greyOverlay
      })}
      style={{ ...style }}
    >
      <div styleName="inner-container">
        {/* <CircularProgress size={size} /> */}
        <img
          src={loadingImg}
          width={size}
          height={size}
          style={{ opacity: 0.6 }}
        />
        {showMessage && message && (
          <p styleName="message" style={{ marginTop: size + 8 }}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

Loading.propTypes = propTypes;

export default Loading;
