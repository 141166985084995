import React from "react";

const UserContext = React.createContext();

function UserProvider(props) {
  const [user, setUser] = React.useState({});
  const value = React.useMemo(() => {
    return {
      user,
      setUser
    };
  }, [user]);
  return <UserContext.Provider value={value} {...props} />;
}

export { UserProvider, UserContext };
