import React, { Component } from "react";

const SDK = require("ringcentral");

class CallBack extends Component {
  componentDidMount() {
    let redirect = function() {
      SDK.handleLoginRedirect();
    };
    redirect();
  }

  render() {
    return <div />;
  }
}

export default CallBack;
