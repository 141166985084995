import React, { useState, useEffect } from "react";
import { object } from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import { Paper, TextField, Button } from "@material-ui/core";
import { SITE_CONFIG } from "../../../config.js";
import { useIntl, FormattedMessage } from "react-intl";

// import LogoImg from "../../../../img/logo.png";
import REQUEST_PASSWORD_RESET from "../../../queries/mutations/REQUEST_PASSWORD_RESET.graphql";
import useInput from "../../../hooks/useFormInput";
import "./ResetRequest.scss";

const propTypes = {
  location: object,
  history: object
};

const ResetRequest = ({ location, history }) => {
  // REFS

  const formRef = React.useRef();

  // STATE

  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, emailDispatch, emailError] = useInput("email", "");
  const intl = useIntl();
  // EFFECTS

  useEffect(() => {
    // console.log("running");
    const locationEmail = _.get(location, "state.email");
    if (locationEmail) {
      emailDispatch({ type: "CHANGE", value: locationEmail });
    }
  }, [emailDispatch, location]);

  // MUTATIONS

  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET);

  // HANDLES

  const handleSubmit = e => {
    e.preventDefault();
    if (errorMessage) {
      setErrorMessage("");
    }
    setSubmitted(true);
    if (formRef.current.checkValidity()) {
      handleResetRequest();
    }
  };

  const handleTexfieldKeyDown = event => {
    if (event.keyCode == 13) {
      handleSubmit(event);
    }
  };

  const handleResetRequest = async () => {
    window.sessionStorage.token = "";
    try {
      // TODO: This function should never error, for security
      await requestPasswordReset({ variables: { email } });
      history.push("/request-sent");
    } catch (error) {
      console.error(error);
      history.push("/request-sent");
    }
  };

  const styles = {
    paper: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: 20,
      width: 400
    }
  };
  return (
    <Paper elevation={2} style={styles.paper}>
      <div>
        <img src={SITE_CONFIG.logo} width="200" styleName="logo" />
      </div>
      <h3 styleName="title">
        {
          <FormattedMessage
            id="resetreq.title"
            defaultMessage="Enter your login email to receive a password reset link"
          />
        }
      </h3>
      {errorMessage && <p styleName="error">{errorMessage}</p>}
      <form ref={formRef} onSubmit={handleSubmit} noValidate>
        <TextField
          style={{ marginTop: 24, marginBottom: 24 }}
          id="email"
          name="email"
          label={intl.messages["login.email"] || "Email"}
          type="email"
          placeholder={intl.messages["login.emailPrompt"] || "Enter email"}
          value={email}
          onChange={e =>
            emailDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{ onKeyDown: handleTexfieldKeyDown }}
          helperText={submitted && emailError}
          error={submitted && !!emailError}
          variant="outlined"
          fullWidth
          required
          autoFocus
        />
        <Button type="submit" color="primary" variant="contained" fullWidth>
          <FormattedMessage id="resetreq.button" defaultMessage="Send email" />
        </Button>
      </form>
    </Paper>
  );
};

ResetRequest.propTypes = propTypes;

export default ResetRequest;
