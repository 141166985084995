import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
  // defaultDataIdFromObject
} from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloProvider as ApolloComponentsProvider } from "@apollo/react-components";
import { VOXJAR_API_SERVER_URI, IS_PRODUCTION } from "./config";
import { UserProvider } from "./context/UserContext.jsx";
import App from "./components/App";

// TODO: extract network stuff to own module?

const httpLink = createHttpLink({ uri: VOXJAR_API_SERVER_URI });
const middlewareLink = setContext((_, { headers }) => {
  const token = window.sessionStorage.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// use with apollo-client
const link = middlewareLink.concat(httpLink);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "UNION",
          name: "Measurement",
          possibleTypes: [
            { name: "CategoricalMeasurement" },
            { name: "RealMeasurement" }
          ]
        },
        {
          kind: "INTERFACE",
          name: "Interaction",
          possibleTypes: [{ name: "Call" }]
        }
      ]
    }
  }
});

const cache = new InMemoryCache({
  // dataIdFromObject: o => {
  //   console.log(o);
  //   return o.id;
  // },
  fragmentMatcher: fragmentMatcher
});

const client = new ApolloClient({
  link,
  // use restore on the cache instead of initialState
  cache: cache.restore(window.__APOLLO_CLIENT__),
  connectToDevTools: !IS_PRODUCTION,
  queryDeduplication: true
});

// Needed for onClick
// http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin();

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <ApolloComponentsProvider client={client}>
        <UserProvider>
          <App />
        </UserProvider>
      </ApolloComponentsProvider>
    </ApolloProvider>
  </Router>,
  document.querySelector("#react-root")
);

export default client;
