import React from "react";

import {
  FirebaseProviderSignIn,
  FirebaseEmailPasswordSignIn
} from "../../../utils/firebase.jsx";

const OauthLogin = () => {
  const styles = {
    paper: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: 20,
      width: 400
    },
    closeIcon: {
      position: "absolute",
      top: 11,
      right: 16,
      cursor: "pointer"
    }
  };
  return (
    <div style={styles.provider}>
      <FirebaseProviderSignIn />
    </div>
  );
};

export default OauthLogin;
