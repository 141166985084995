// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserMenu__name{margin:0 8px;font-size:16px}.UserMenu__badge{padding:0px 3px;min-width:16px;height:16px;border-radius:8px;background-color:#ff9800;color:#fff;position:absolute;top:6px;right:4px;font-size:12px;font-weight:600;display:none;align-items:center;justify-content:center}.UserMenu__badge.UserMenu__show{display:flex}.UserMenu__image{height:100px}\n", ""]);
// Exports
exports.locals = {
	"name": "UserMenu__name",
	"badge": "UserMenu__badge",
	"show": "UserMenu__show",
	"image": "UserMenu__image"
};
module.exports = exports;
