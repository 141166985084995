import React, { useState } from "react";
import _ from "lodash";
import empty_img from "../../../../../img/no_notifications.svg";
import { Divider, MenuList, MenuItem } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CommentIcon from "@material-ui/icons/Comment";
import { object, func, number } from "prop-types";
import { useQuery } from "@apollo/react-hooks";

import { useIntl } from "react-intl";
import useDeepCompareEffect from "use-deep-compare-effect";
import GET_SCORES from "../../../../queries/GET_SCORES.graphql";
import GET_MESSAGES from "../../../../queries/GET_MESSAGES.graphql";

import "./InboxMenuItem.scss";

const propTypes = {
  user: object,
  location: object,
  handleNav: func,
  notificationCount: number
};

const NotificationsDropdown = ({ user, handleNav, notificationCount }) => {
  const intl = useIntl();
  const notifications = _.get(user, "notifications.edges", []);

  const [unreadMessageIds, setUnreadMessageIds] = useState([]);
  const [unseenScoreIds, setUnseenScoreIds] = useState([]);
  // const messages = 6;
  // TODO get new notifications
  useDeepCompareEffect(() => {
    const unreadMessages = [];
    const readMessages = [];
    const unseenScores = [];
    const seenScores = [];

    notifications.forEach(({ notification }) => {
      if (notification.status === "UNREAD") {
        if (notification.event === "MESSAGE_CREATED") {
          unreadMessages.push(notification.refersTo);
        } else {
          unseenScores.push(notification.refersTo);
        }
      } else {
        if (notification.event === "MESSAGE_CREATED") {
          readMessages.push(notification.refersTo);
        } else {
          seenScores.push(notification.refersTo);
        }
      }
    });
    setUnreadMessageIds(unreadMessages);
    setUnseenScoreIds(unseenScores);
  }, [user]);

  const messagesOptions = {
    variables: {
      filters: {
        boolean: {
          filter: [
            {
              terms: {
                field: "id",
                values: unreadMessageIds
              }
            }
          ]
        }
      }
    },
    skip: unreadMessageIds.length === 0
  };

  const { data: mData } = useQuery(GET_MESSAGES, messagesOptions);
  const messages = _.get(mData, "messages.edges", []);

  // console.log(messages);

  const scoresOptions = {
    variables: {
      showCallData: true,
      filters: {
        boolean: {
          filter: [
            {
              terms: {
                field: "id",
                values: unseenScoreIds
              }
            }
          ]
        }
      }
    },
    skip: unseenScoreIds.length === 0
  };

  const { data: sData } = useQuery(GET_SCORES, scoresOptions);
  const scores = _.get(sData, "scores.edges", []);

  const notificationList = [...scores, ...messages];

  return (
    <div styleName="dropdownContainer">
      <div styleName="dropdownHeader">{notificationCount} Unread Messages</div>
      <div styleName="dropdownBody">
        {notificationCount < 1 ? (
          <div styleName="imgWrapper">
            <img styleName="image" src={empty_img} />
          </div>
        ) : (
          <MenuList>
            {notificationList.map(n => {
              return (
                <div key={n?.score ? n.score.id : n.message.id}>
                  {n?.score ? (
                    <MenuItem
                      onClick={() =>
                        handleNav({
                          type: "scores",
                          id: n.score.interaction.id
                        })
                      }
                    >
                      <div styleName="row">
                        <AssignmentTurnedInIcon fontSize="small" />
                        <div>
                          <span styleName="label">New Score</span> by{" "}
                          {n.score.owner.name}
                        </div>
                      </div>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() =>
                        handleNav({ type: "comments", id: n.message.refersTo })
                      }
                    >
                      <div styleName="row">
                        <CommentIcon fontSize="small" />{" "}
                        <div>
                          <span styleName="label">New Comment</span> by{" "}
                          {n.message.owner.name}
                        </div>
                      </div>
                    </MenuItem>
                  )}
                </div>
              );
            })}
          </MenuList>
        )}
      </div>
      <div styleName="dropdownFooter">
        <Divider />
        <p styleName="link" onClick={() => handleNav({})}>
          {intl.messages["inbox.open"] || "Open Inbox"}
        </p>
      </div>
    </div>
  );
};
NotificationsDropdown.propTypes = propTypes;
export default NotificationsDropdown;
