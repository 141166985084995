import React from "react";
import { hot } from "react-hot-loader/root";
import { Route, Switch } from "react-router-dom";
// import "moment/locale/en";
import moment from "moment";
import "moment/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import _ from "lodash";
import Favicon from "react-favicon";
import Helmet from "react-helmet";
import { IntlProvider } from "react-intl";

import { SITE_CONFIG } from "../config.js";
import ThemeProvider from "./ThemeProvider/ThemeProvider";
// import CreateAccount from "./PublicRoutes/CreateAccount";
import CreateAccount from "./PublicRoutes/CreateAccount/CreateAccount";
import Signup from "./PublicRoutes/Signup/Signup";
import Login from "./PublicRoutes/Login/Login";
import OauthLogin from "./PublicRoutes/Login/OauthLogin";
import ResetRequest from "./PublicRoutes/ResetRequest/ResetRequest";
import ResetPassword from "./PublicRoutes/ResetPassword/ResetPassword";
import ResetRequestSent from "./PublicRoutes/ResetRequestSent/ResetRequestSent";
import Thanks from "./Thanks/Thanks.jsx";
import RingCentralCallback from "./Main/Integrations/ring_central_auth_callback";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Main from "./Main/Main.jsx";
import { useUser } from "../hooks/useUser.js";
// import Onboarding from "./Onboarding/Onboarding.jsx";
import messages_es from "../../../lang/es.json";
import "./App.scss";

// import routes from "./routes";

const maintenanceMode = false;

const localLanguage = navigator.language.split(/[-_]/)[0];

const messages = {
  es: messages_es
};

const App = () => {
  const { user } = useUser();
  const getLanguage = () => {
    // Can use site config to override this for custom domains if needed
    const shouldUseCustom = _.get(user, "settings.language.type") === "custom";

    if (shouldUseCustom) {
      const lang = _.get(user, "settings.language.lang", localLanguage) || "en";
      moment.locale(lang);
      return lang;
    } else {
      const lang = SITE_CONFIG.language || localLanguage || "en";
      moment.locale(lang);
      return lang;
    }
  };

  return (
    <ThemeProvider>
      <IntlProvider
        locale={getLanguage()}
        defaultLocale="en"
        messages={messages[getLanguage()]}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <>
            {SITE_CONFIG.navName && (
              <Helmet>
                <title>{SITE_CONFIG.navName}</title>
                <meta name={SITE_CONFIG.navName} />
              </Helmet>
            )}
            {SITE_CONFIG.favicon && <Favicon url={SITE_CONFIG.favicon} />}
            <Switch>
              <Route
                path={maintenanceMode ? "/" : "/login"}
                component={SITE_CONFIG.login.voxjar ? Login : OauthLogin}
              />
              {!maintenanceMode && SITE_CONFIG.CreateAccount.route && (
                <Route path="/create-account" component={CreateAccount} />
              )}
              {!maintenanceMode && SITE_CONFIG.ResetPassword.route && (
                <Route path="/reset-request" component={ResetRequest} />
              )}
              {!maintenanceMode && SITE_CONFIG.ResetPassword.route && (
                <Route path="/reset-password" component={ResetPassword} />
              )}
              {!maintenanceMode && SITE_CONFIG.ResetPassword.route && (
                <Route path="/request-sent" component={ResetRequestSent} />
              )}
              {!maintenanceMode && SITE_CONFIG.Signup.route && (
                <Route path="/signup" component={Signup} />
              )}
              {!maintenanceMode && SITE_CONFIG.Integrations.route && (
                <Route path="/oauth/:integrator" component={Thanks} />
              )}
              {!maintenanceMode && SITE_CONFIG.Integrations.route && (
                <Route
                  path="/ring-central-auth-callback"
                  component={RingCentralCallback}
                />
              )}
              {!maintenanceMode && <PrivateRoute path="/" component={Main} />}
              {/* <PrivateRoute path="/getting-started" component={Onboarding} /> */}
            </Switch>
          </>
        </MuiPickersUtilsProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default hot(App);
