import React, { useState, useEffect } from "react";
import { object } from "prop-types";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Paper, Button, TextField } from "@material-ui/core";
import searchToObject from "../../../utils/searchToObject";
import { SITE_CONFIG } from "../../../config.js";
// import LogoImg from "../../../../img/logo.png";
import RESET_PASSWORD from "../../../queries/mutations/RESET_PASSWORD.graphql";
import useInput from "../../../hooks/useFormInput";
import "./ResetPassword.scss";
import { useIntl, FormattedMessage } from "react-intl";

const propTypes = {
  history: object,
  location: object
};

const ResetPassword = ({ history, location }) => {
  // REFS

  const formRef = React.useRef();

  // STATE

  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, emailDispatch, emailError] = useInput("email", "");
  const [password, passwordDispatch, passwordError] = useInput("password", "");
  const [passwordConf, passwordConfDispatch, passwordConfError] = useInput(
    "password-confirm",
    ""
  );
  const [oobCode, setOobCode] = useState("");
  const intl = useIntl();
  // EFFECTS

  const handleParams = async () => {
    try {
      window.sessionStorage.token = "";
      const query = searchToObject(location.search);

      // const email = await VerifyOobCode(query.oobCode)
      const email = query.email;
      setOobCode(query.oobCode);
      emailDispatch({ type: "CHANGE", value: email });
    } catch (error) {
      // console.error(error);
      setErrorMessage(
        intl.messages["formerror.expired"] ||
          "Error: Your reset link may have expired. You can request a new one here: "
      );
    }
  };

  useEffect(() => {
    handleParams();
  }, [emailDispatch, location.search]);

  // MUTATIONS

  const [resetPassword] = useMutation(RESET_PASSWORD);

  // HANDLES
  console.log(submitted);
  const handleSubmit = e => {
    e.preventDefault();
    if (errorMessage) {
      setErrorMessage("");
    }
    setSubmitted(true);
    if (formRef.current.checkValidity()) {
      handleResetPassword();
    }
  };

  const handleTexfieldKeyDown = event => {
    if (event.keyCode == 13) {
      handleSubmit(event);
    }
  };

  const handleResetPassword = async () => {
    try {
      // const token = searchToObject(location.search).token;
      window.sessionStorage.token = "";

      await resetPassword({
        variables: { password, oobCode }
      });

      history.push("/login");
    } catch (error) {
      // console.error(error);
      setErrorMessage(
        intl.messages["formerror.expired"] ||
          "Error: Your reset link may have expired. You can request a new one here: "
      );
    }
  };

  // DOM

  const styles = {
    paper: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: 20,
      width: 400
    }
  };

  return (
    <Paper elevation={2} style={styles.paper}>
      <div>
        <img src={SITE_CONFIG.logo} width="200px" styleName="logo" />
      </div>
      <h3 styleName="title">
        <FormattedMessage
          id="reset.title"
          defaultMessage="Enter a new password to log in to your account"
        />
      </h3>
      {errorMessage && (
        <p styleName="error">
          {errorMessage}{" "}
          <Link
            to={{
              pathname: `/reset-request`,
              state: { email }
            }}
            style={{ color: "red" }}
          >
            <FormattedMessage
              id="formerror.title"
              defaultMessage="Forgot password page"
            />
          </Link>
        </p>
      )}
      <form ref={formRef} onSubmit={handleSubmit} noValidate>
        <TextField
          style={{ marginTop: 24 }}
          id="email"
          name="email"
          label={intl.messages["login.email"] || "Email"}
          type="email"
          placeholder={intl.messages["login.emailPrompt"] || "Enter email"}
          value={email}
          onChange={e =>
            emailDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{ onKeyDown: handleTexfieldKeyDown }}
          helperText={submitted && emailError}
          error={submitted && !!emailError}
          variant="outlined"
          autoComplete="off"
          disabled
          fullWidth
          required
        />
        <TextField
          style={{ marginTop: 24 }}
          id="password"
          label={
            <FormattedMessage
              id="login.password"
              defaultMessage="Confirm password"
            />
          }
          type="password"
          placeholder={
            intl.messages["login.passwordPrompt"] || "Enter password"
          }
          value={password}
          onChange={e =>
            passwordDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            minLength: 8,
            pattern: ".*([A-Z]).*",
            "data-error":
              intl.messages["formerror.caps"] || "Must have a capital letter"
          }}
          helperText={
            (submitted && passwordError) ||
            intl.messages["formerror.capsLength"] ||
            "Must have a capital letter and be at least 8 characters in length"
          }
          error={submitted && !!passwordError}
          variant="outlined"
          autoComplete="new-password"
          fullWidth
          required
          autoFocus
        />
        <TextField
          style={{ marginTop: 24, marginBottom: 24 }}
          id="password-confirm"
          label={
            <FormattedMessage
              id="create.confirmPassword"
              defaultMessage="Confirm password"
            />
          }
          type="password"
          placeholder={
            intl.messages["create.passwordPrompt"] || "Enter password again"
          }
          value={passwordConf}
          onChange={e =>
            passwordConfDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            pattern: password.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
            "data-error":
              intl.messages["formerror.match"] || "Must match password"
          }}
          helperText={submitted && passwordConfError}
          error={submitted && !!passwordConfError}
          variant="outlined"
          fullWidth
          required
        />
        <Button type="submit" color="primary" variant="contained" fullWidth>
          <FormattedMessage
            id="reset.button"
            defaultMessage="Save New Password"
          />
        </Button>
      </form>
    </Paper>
  );
};

ResetPassword.propTypes = propTypes;

export default ResetPassword;
