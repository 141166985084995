import React, { Component } from "react";
import { string, object } from "prop-types";

import LogoImg from "../../../img/logo.png";
import searchToObject from "../../utils/searchToObject.jsx";
import { IS_PRODUCTION, SITE_CONFIG } from "../../config.js";
import "./Thanks.scss";

const origin = SITE_CONFIG.origin;

class Thanks extends Component {
  static propTypes = {
    message: string,
    location: object
  };

  componentDidMount() {
    const search = searchToObject(this.props.location.search);
    console.log(search);
    window.opener.postMessage(
      { search, source: "integration-success" },
      origin
    );
    window.close();
  }
  render() {
    const { message } = this.props;
    return (
      <div styleName="container">
        <img src={LogoImg} width="200" style={{ paddingTop: 10 }} />
        <h3>{message || "Thanks for integrating, you are all set!"}</h3>
        <button
          onClick={() => {
            window.close();
            return false;
          }}
        >
          Close window
        </button>
      </div>
    );
  }
}

export default Thanks;
