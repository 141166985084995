import React, { useState, useEffect } from "react";
import { object } from "prop-types";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, MenuList, MenuItem } from "@material-ui/core";
import { SITE_CONFIG } from "../../../../config.js";
import { FormattedMessage } from "react-intl";

import Popover from "../../../Popover/Popover.jsx";
// import authorization from "../../../../utils/authorization.jsx";
import "./MainNavigation.scss";

const mainNavPaths = [
  "/dashboard",
  "/discover",
  "/agents",
  "/call-randomizer",
  "/library"
];

const agentNavPaths = ["/dashboard", "/agents", "/library"];

const styles = {
  tab: {
    minHeight: 56,
    minWidth: 120,
    fontSize: "1rem",
    textTransform: "capitalize"
  }
};

const propTypes = {
  userInfo: object,
  history: object,
  location: object
};

const MainNavigation = ({ userInfo, history, location }) => {
  // const isAgent = !authorization.can(userInfo, [
  //   "READ_OTHERS_CALLS",
  //   "UPDATE_OTHERS_SCORES"
  // ]);
  const isAgent = userInfo.role === "BASIC";

  // STATE

  const [navTab, setNavTab] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // EFFECTS

  useEffect(() => {
    const path = location.pathname;
    const paths = isAgent ? agentNavPaths : mainNavPaths;
    if (paths.indexOf(path) === -1 && path.indexOf("/agents") === -1) {
      setNavTab(false);
    } else {
      if (path.indexOf("/agents") === 0 || path === "/call-randomizer") {
        setNavTab("scoring");
      } else {
        setNavTab(path);
      }
    }
  }, [isAgent, location.pathname]);

  // HANDLES

  const handleNavTabSelect = (event, navTab) => {
    setNavTab(navTab);
  };

  const handleClick = (e, path) => {
    e.preventDefault();
    if (!path) {
      setMenuAnchorEl(e.currentTarget);
    } else {
      history.push(path);
    }
  };

  const sanitizeId = userId => {
    const newId = userId.replace(/\W/g, "");
    return newId;
  };

  // DOM

  return (
    <div styleName="navTabs">
      <Tabs value={navTab}>
        {isAgent
          ? [
              SITE_CONFIG.Dashboard.show && (
                <Tab
                  key="/dashboard"
                  component="a"
                  style={styles.tab}
                  label={
                    <FormattedMessage
                      id="nav.dashboard"
                      defaultMessage="Dashboard"
                    />
                  }
                  value="/dashboard"
                  onSelect={handleNavTabSelect}
                  onClick={e => handleClick(e, "/dashboard")}
                />
              ),
              <Tab
                key="/agents"
                component="a"
                style={styles.tab}
                label={
                  <FormattedMessage id="nav.calls" defaultMessage="Calls" />
                }
                value="scoring"
                onClick={e =>
                  handleClick(e, `/agents/${sanitizeId(userInfo.id)}`)
                }
              />
            ]
          : [
              SITE_CONFIG.Dashboard.show && (
                <Tab
                  key="/dashboard"
                  component="a"
                  style={styles.tab}
                  label={
                    <FormattedMessage
                      id="nav.dashboard"
                      defaultMessage="Dashboard"
                    />
                  }
                  value="/dashboard"
                  onSelect={handleNavTabSelect}
                  onClick={e => handleClick(e, "/dashboard")}
                />
              ),
              SITE_CONFIG.Discover.show && (
                <Tab
                  key="/discover"
                  component="a"
                  style={styles.tab}
                  label={
                    <FormattedMessage
                      id="nav.discover"
                      defaultMessage="Discover"
                    />
                  }
                  value="/discover"
                  onSelect={handleNavTabSelect}
                  onClick={e => handleClick(e, "/discover")}
                />
              ),
              SITE_CONFIG.Scoring.show && (
                <Tab
                  key="scoring"
                  component="a"
                  style={styles.tab}
                  label={
                    <FormattedMessage
                      id="nav.scoring"
                      defaultMessage="Scoring"
                    />
                  }
                  value="scoring"
                  onClick={e => handleClick(e)}
                />
              )
            ]}
        {SITE_CONFIG.Library.route && (
          <Tab
            component="a"
            style={styles.tab}
            label={
              <FormattedMessage id="nav.library" defaultMessage="Library" />
            }
            value="/library"
            onSelect={handleNavTabSelect}
            onClick={e => handleClick(e, "/library")}
          />
        )}
      </Tabs>
      <Popover
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        placement="bottom-start"
      >
        <MenuList>
          {SITE_CONFIG.Scoring.AgentTable.route && (
            <MenuItem
              onClick={() => {
                history.push("/agents");
                setMenuAnchorEl(null);
              }}
            >
              {
                <FormattedMessage
                  id="nav.agentScores"
                  defaultMessage="Agent Scores"
                />
              }
            </MenuItem>
          )}
          {SITE_CONFIG.Scoring.Randomizer.route && (
            <MenuItem
              onClick={() => {
                history.push("/call-randomizer");
                setMenuAnchorEl(null);
              }}
            >
              {
                <FormattedMessage
                  id="nav.randomizer"
                  defaultMessage="Call Randomizer"
                />
              }
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </div>
  );
};

MainNavigation.propTypes = propTypes;

export default withRouter(MainNavigation);
