import React, { useState, useEffect } from "react";
import { object, func, node, bool, oneOf, number } from "prop-types";
import { Popper, Grow, Paper, ClickAwayListener } from "@material-ui/core";

const propTypes = {
  children: node,
  open: bool,
  anchorEl: object,
  placement: oneOf([
    "top-start",
    "top",
    "top-end",
    "left-start",
    "left",
    "left-end",
    "right-start",
    "right",
    "right-end",
    "bottom-start",
    "bottom",
    "bottom-end"
  ]),
  disablePortal: bool,
  useClickAway: bool,
  matchWidth: bool,
  addToWidth: number,
  noMaxHeight: bool,
  style: object,
  onClose: func
};

// uses open if provided, else just checks if anchorEl exists
const Popover = ({
  children,
  open,
  anchorEl,
  placement = "bottom-end",
  disablePortal,
  useClickAway,
  matchWidth,
  addToWidth = 0,
  noMaxHeight,
  style,
  onClose
}) => {
  const [prevAchorEl, setPrevAnchorEl] = useState(null);
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (anchorEl) {
      setPrevAnchorEl(anchorEl);
    }
  }, [anchorEl]);

  useEffect(() => {
    if (open != null ? open : !!anchorEl) {
      setShowChildren(true);
    }
  }, [open, anchorEl]);

  return (
    <Popper
      open={open != null ? open : !!anchorEl}
      anchorEl={anchorEl}
      placement={placement}
      disablePortal={disablePortal}
      modifiers={{
        offset: {
          offset: "0, 4"
        }
      }}
      transition
      style={{
        zIndex: 1300,
        ...(matchWidth &&
          prevAchorEl && { width: prevAchorEl.offsetWidth + addToWidth }),
        ...style
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          onExited={node => {
            TransitionProps.onExited(node);
            setShowChildren(false);
          }}
          style={{
            transformOrigin:
              placement.indexOf("top") > -1 ? "center bottom" : "center top"
          }}
        >
          <Paper
            style={{
              minWidth: 120,
              ...(!noMaxHeight && { maxHeight: 400 }),
              overflow: "auto"
            }}
          >
            {onClose ? (
              <ClickAwayListener
                mouseEvent={useClickAway != null ? useClickAway : "onMouseUp"}
                onClickAway={onClose}
              >
                <div>{showChildren && children}</div>
              </ClickAwayListener>
            ) : (
              <div>{showChildren && children}</div>
            )}
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

Popover.propTypes = propTypes;

export default Popover;
