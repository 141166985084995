import React, { useState } from "react";
import { object } from "prop-types";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Paper, Button, TextField } from "@material-ui/core";
import { SITE_CONFIG } from "../../../config.js";
import { useIntl, FormattedMessage } from "react-intl";

import authorization from "../../../utils/authorization.jsx";
// import LogoImg from "../../../../img/logo.png";
// import CREATE_COMPANIES from "../../../queries/mutations/CREATE_COMPANIES.graphql";
// import SIGN_IN_WITH_PASSWORD from "../../../queries/mutations/SIGN_IN_WITH_PASSWORD.graphql";
import CREATE_ACCOUNT from "../../../queries/mutations/CREATE_ACCOUNT.graphql";
import useInput from "../../../hooks/useFormInput";
import "./CreateAccount.scss";

const propTypes = {
  history: object
};

const CreateAccount = ({ history }) => {
  // REFS

  const formRef = React.useRef();

  // STATE

  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [companyName, companyNameDispatch, companyNameError] = useInput(
    "company-name",
    ""
  );
  const [name, nameDispatch, nameError] = useInput("name", "");
  const [email, emailDispatch, emailError] = useInput("email", "");
  const [password, passwordDispatch, passwordError] = useInput("password", "");
  const [passwordConf, passwordConfDispatch, passwordConfError] = useInput(
    "password-confirm",
    ""
  );
  const intl = useIntl();

  // MUTATIONS

  const [createAccount] = useMutation(CREATE_ACCOUNT);

  // HANDLES

  const handleSubmit = e => {
    e.preventDefault();
    if (errorMessage) {
      setErrorMessage("");
    }
    setSubmitted(true);
    if (formRef.current.checkValidity()) {
      handleCreateCompanyAndAccount();
    }
  };

  const handleTexfieldKeyDown = event => {
    if (event.keyCode == 13) {
      handleSubmit(event);
    }
  };

  const handleCreateCompanyAndAccount = async () => {
    try {
      const options = {
        variables: {
          account: {
            id: email,
            email: email,
            password: password,
            name: name,
            companies: [
              {
                name: companyName
              }
            ]
          }
        }
      };
      const { data } = await createAccount(options);
      const { token, expiresAt } = data.createAccount;
      window.sessionStorage.token = token;
      window.sessionStorage.tokenExpiresAt = expiresAt;
      history.push("/getting-started");
    } catch (error) {
      // TODO: Ask will for more detailed error codes and show better messages to the user
      console.error(error);
      setErrorMessage(
        intl.messages["create.error1"]
          ? `${intl.messages["create.error1"]} ${SITE_CONFIG.supportEmail} ${intl.messages["create.error2"]}`
          : `Error: Something went wrong. Please email us at ${SITE_CONFIG.supportEmail} for assistance.`
      );
    }
  };

  const styles = {
    paper: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: 20,
      width: 400
    }
  };
  // const agentOptions = [
  //   <MenuItem key={1} value="1-50" primaryText="50 or fewer" />,
  //   <MenuItem key={2} value="51-100" primaryText="51-100" />,
  //   <MenuItem key={3} value="100-250" primaryText="101-250" />,
  //   <MenuItem key={4} value="250+" primaryText="more than 250" />
  // ];
  return (
    <Paper elevation={2} style={styles.paper}>
      <div>
        <img src={SITE_CONFIG.logo} width="200px" styleName="logo" />
      </div>
      <h3 styleName="title">
        <FormattedMessage
          id="create.title"
          defaultMessage="Sign up now to start your free trial."
        />
      </h3>
      <p styleName="info">
        <FormattedMessage
          id="create.subTitle"
          defaultMessage="Please complete all fields"
        />
      </p>
      {errorMessage && <p styleName="error">{errorMessage}</p>}
      <form ref={formRef} onSubmit={handleSubmit} noValidate>
        <TextField
          style={{ marginTop: 24 }}
          id="company-name"
          label={
            <FormattedMessage
              id="create.companyName"
              defaultMessage="Company name"
            />
          }
          type="text"
          placeholder={
            intl.messages["create.companyPrompt"] || "Enter your company name"
          }
          value={companyName}
          onChange={e =>
            companyNameDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            minLength: 2,
            maxLength: 40
          }}
          helperText={submitted && companyNameError}
          error={submitted && !!companyNameError}
          variant="outlined"
          autoFocus
          fullWidth
          required
        />
        <TextField
          style={{ marginTop: 24 }}
          id="name"
          label={<FormattedMessage id="create.name" defaultMessage="Name" />}
          type="text"
          placeholder={
            intl.messages["create.namePrompt"] || "Enter your full name"
          }
          value={name}
          onChange={e =>
            nameDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            minLength: 2,
            maxLength: 40
          }}
          helperText={submitted && nameError}
          error={submitted && !!nameError}
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          style={{ marginTop: 24 }}
          id="email"
          name="email"
          label={intl.messages["login.email"] || "Email"}
          type="email"
          placeholder={intl.messages["login.emailPrompt"] || "Enter email"}
          value={email}
          onChange={e =>
            emailDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{ onKeyDown: handleTexfieldKeyDown }}
          helperText={submitted && emailError}
          error={submitted && !!emailError}
          variant="outlined"
          autoComplete="off"
          fullWidth
          required
        />
        <TextField
          style={{ marginTop: 24 }}
          id="password"
          label={
            <FormattedMessage id="login.password" defaultMessage="Password" />
          }
          type="password"
          placeholder={
            intl.messages["login.passwordPrompt"] || "Enter password"
          }
          value={password}
          onChange={e =>
            passwordDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            minLength: 8,
            pattern: ".*([A-Z]).*",
            "data-error":
              intl.messages["formerror.caps"] || "Must have a capital letter"
          }}
          helperText={
            (submitted && passwordError) ||
            intl.messages["formerror.capsLength"] ||
            "Must have a capital letter and be at least 8 characters in length"
          }
          error={submitted && !!passwordError}
          variant="outlined"
          autoComplete="new-password"
          fullWidth
          required
        />
        <TextField
          style={{ marginTop: 24, marginBottom: 24 }}
          id="password-confirm"
          label={
            <FormattedMessage
              id="create.confirmPassword"
              defaultMessage="Confirm password"
            />
          }
          type="password"
          placeholder={
            intl.messages["create.passwordPrompt"] || "Enter password again"
          }
          value={passwordConf}
          onChange={e =>
            passwordConfDispatch({ type: "CHANGE", value: e.target.value })
          }
          inputProps={{
            onKeyDown: handleTexfieldKeyDown,
            pattern: password,
            "data-error":
              intl.messages["formerror.match"] || "Must match password"
          }}
          helperText={submitted && passwordConfError}
          error={submitted && !!passwordConfError}
          variant="outlined"
          fullWidth
          required
        />
        <Button type="submit" color="primary" variant="contained" fullWidth>
          <FormattedMessage
            id="create.button"
            defaultMessage="Create my account"
          />
        </Button>
      </form>
      <p styleName="post-info">
        <FormattedMessage
          id="create.haveAccount"
          defaultMessage="Already have an account?"
        />{" "}
        <Link to="/login" styleName="reset-link">
          <FormattedMessage id="login.button" defaultMessage="Login" />
        </Link>
      </p>
    </Paper>
  );
};

CreateAccount.propTypes = propTypes;

export default CreateAccount;
