// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResetRequestSent__alert{position:fixed;top:10%;left:0;right:0;width:500px;margin:0 auto;text-align:center;background-color:#272727;color:#fff;padding:16px;box-shadow:0px 4px 6px 0px rgba(0,0,0,0.4)}.ResetRequestSent__logo{margin:16px auto;width:200px;display:block}.ResetRequestSent__title{font-weight:600;text-align:center;line-height:normal}.ResetRequestSent__content{margin:12px 0 24px;font-size:16px;text-align:center;line-height:normal}\n", ""]);
// Exports
exports.locals = {
	"alert": "ResetRequestSent__alert",
	"logo": "ResetRequestSent__logo",
	"title": "ResetRequestSent__title",
	"content": "ResetRequestSent__content"
};
module.exports = exports;
