import React, { useState } from "react";
import { node } from "prop-types";
import { Dialog as MuiDialog } from "@material-ui/core";

/**
 * This is a wrapper for Material-Ui Dialog that simply renders a new child
 * everytime it opens
 */

const Dialog = props => {
  const [renderChildren, setRenderChildren] = useState(false);
  return (
    <MuiDialog
      onEnter={() => setRenderChildren(true)}
      onExited={() => setRenderChildren(false)}
      {...props}
    >
      {renderChildren ? props.children : false}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  children: node
};

export default Dialog;
