import React from "react";
import { UserContext } from "../context/UserContext.jsx";

function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  const { user, setUser } = context;
  const company = user?.companies?.edges?.[0]?.company;
  return {
    user,
    company,
    setUser
  };
}

export { useUser };
