import React, { Fragment, useState } from "react";
import { func } from "prop-types";
// import { useMutation } from "@apollo/react-hooks";
// import _ from "lodash";
// import cx from "classnames";
import { Button, MenuList, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useIntl } from "react-intl";
import SignInAsUserDialog from "../SignInAsUserDialog/SignInAsUserDialog";
import Popover from "../../../Popover/Popover.jsx";
import { useUser } from "../../../../hooks/useUser.js";
import "./UserMenu.scss";

const propTypes = {
  onPathChange: func
};

const UserMenu = () => {
  const intl = useIntl();
  const { user } = useUser();

  // STATE

  const [anchorEl, setAnchorEl] = useState(null);
  const [signInAsUserDialogOpen, setSignInAsUserDialogOpen] = useState(false);

  // HANDLES

  const handleMenuToggle = e => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = e => {
    if (e && anchorEl.contains(e.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    window.sessionStorage.clear();
    // a reload resets the client store
    window.location.reload();
  };

  // const handlePage = path => {
  //   handleClose();
  //   onPathChange(path);
  // };

  const handleSignInAsUserToggle = () => {
    handleClose();
    setSignInAsUserDialogOpen(prev => !prev);
  };

  // const unreadNotifications = (() => {
  //   const notifications = _.get(user, "notifications.edges", []);
  //   return notifications.reduce(
  //     (r, { notification }) => r + (notification.status === "UNREAD" ? 1 : 0),
  //     0
  //   );
  // })();

  const styles = {
    button: {
      color: "#fff",
      textTransform: "capitalize",
      fontWeight: 400
    }
  };
  return (
    <Fragment>
      <Button style={styles.button} onClick={handleMenuToggle}>
        <span styleName="name">{user.name}</span>
        {/* <div styleName={cx("badge", { show: !!unreadNotifications })}>
          {unreadNotifications}
        </div> */}
        <ArrowDropDown />
      </Button>
      <Popover anchorEl={anchorEl} onClose={handleClose}>
        <MenuList>
          {/* <MenuItem onClick={() => handlePage("/notifications")}>
            {intl.messages["inbox"] || "Inbox"}
            {!!unreadNotifications && ` (${unreadNotifications})`}
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>
            {intl.messages["logout"] || "Logout"}
          </MenuItem>
          {user.role === "SUPERUSER" && (
            <MenuItem onClick={handleSignInAsUserToggle}>
              Sign in as user
            </MenuItem>
          )}
        </MenuList>
      </Popover>
      <SignInAsUserDialog
        open={signInAsUserDialogOpen}
        onClose={handleSignInAsUserToggle}
      />
    </Fragment>
  );
};

UserMenu.propTypes = propTypes;

export default UserMenu;
