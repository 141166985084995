import React, { useState } from "react";
import _ from "lodash";
import { Tooltip, IconButton } from "@material-ui/core";
import { object, func, bool } from "prop-types";
import cx from "classnames";
import InboxIcon from "@material-ui/icons/Inbox";
import { useIntl } from "react-intl";
import Popover from "../../../Popover/Popover.jsx";
import NotificationsDropdown from "./NotificationsDropdown.jsx";
import "./InboxMenuItem.scss";
// import { useEffect } from "react";

const propTypes = {
  user: object,
  location: object,
  onPathChange: func,
  onboarding: bool
};

const InboxMenuItem = ({ onPathChange, user }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [notifications, setNotifications] = useState(0)
  const styles = {
    icon: {
      margin: "0 4px",
      color: "#fff"
    }
  };

  // HANDLES

  const handleMenuToggle = e => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = e => {
    if (e && anchorEl.contains(e.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleNav = q => {
    onPathChange("/notifications", q);
    handleClose();
  };

  const unreadNotifications = (() => {
    const notifications = _.get(user, "notifications.edges", []);
    return notifications.reduce(
      (r, { notification }) => r + (notification.status === "UNREAD" ? 1 : 0),
      0
    );
  })();

  // const getReferences = () => {

  //   setNotifications(1);
  // };

  // useEffect(() => {
  //   getReferences();
  // }, [unreadNotifications]);

  return (
    <>
      <Tooltip
        title={intl.messages["inbox"] || "Inbox"}
        enterDelay={500}
        leaveDelay={200}
      >
        <IconButton
          aria-label="Inbox"
          style={styles.icon}
          // onClick={() => onPathChange("/notifications")}
          onClick={handleMenuToggle}
        >
          <InboxIcon
            color={
              location.pathname.indexOf("notifications") > -1
                ? "secondary"
                : "inherit"
            }
          />
          <div styleName={cx("badge", { show: !!unreadNotifications })}>
            {unreadNotifications}
          </div>
        </IconButton>
      </Tooltip>
      <Popover anchorEl={anchorEl} onClose={handleClose}>
        <NotificationsDropdown
          user={user}
          handleNav={handleNav}
          notificationCount={unreadNotifications}
          // notificationEdge=
        />
      </Popover>
    </>
  );
};
InboxMenuItem.propTypes = propTypes;
export default InboxMenuItem;
