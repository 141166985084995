export default function searchToObject(locationSearch) {
  if (locationSearch) {
    var search = locationSearch.substring(1);
    return JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function(key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
  } else {
    return {};
  }
}
