import React, { useState, useEffect } from "react";
import { object, any } from "prop-types";
import _ from "lodash";
import { Route, Redirect, withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import useDeepCompareEffect from "use-deep-compare-effect";

import AuthExpirationAlert from "./AuthExpirationAlert/AuthExpirationAlert";
import Loading from "../Loading/Loading.jsx";
import { useUser } from "../../hooks/useUser";
import SIGN_IN_TO from "../../queries/mutations/SIGN_IN_TO.graphql";
import GET_CURRENT_USER from "../../queries/GET_CURRENT_USER.graphql";

const EXPIRATION_ALERT_DURATION = 5 * 60; // 5 minutes
const NON_ACTIVITY_DURATION = 10 * 60; // 10 minutes

const propTypes = {
  component: any,
  // from withRouter
  location: object,
  history: object
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { history } = rest;
  const lastClickAtRef = React.useRef(moment()); // moment
  const tokenExpirationRef = React.useRef(); // moment

  // CONTEXT

  const { user, setUser } = useUser();

  // STATE

  const [loaded, setLoaded] = useState(false);
  const [expAlertOpen, setExpAlertOpen] = useState(false);

  // QUERIES

  const options = {
    pollInterval: 30000
  };
  const { data, loading, error } = useQuery(GET_CURRENT_USER, options);

  let currentUser = _.get(data, "me", null);

  const isAdminOrHigher =
    !!currentUser &&
    (currentUser.role === "ADMIN" || currentUser.role === "SUPERUSER");

  const adminOptions = {
    variables: {
      withIntegrationsSettings: isAdminOrHigher
    },
    skip: !isAdminOrHigher
  };
  const { data: integrationData } = useQuery(GET_CURRENT_USER, adminOptions);

  if (isAdminOrHigher && integrationData) {
    currentUser = {
      ...currentUser,
      companies: _.get(integrationData, "me", {}).companies
    };
  }

  // MUTATIONS

  const [signInTo] = useMutation(SIGN_IN_TO);

  // EFFECTS

  useDeepCompareEffect(() => {
    function onCompleted(data) {
      setUser(data);
      // on first load, save expiration time, and set up inline manual
      if (!loaded) {
        setLoaded(true);

        tokenExpirationRef.current = moment(
          window.sessionStorage.tokenExpiresAt
        );
      }
    }

    function onError(err) {
      console.error(err);
      // if error on query, redirect to /login
      history.replace({
        pathname: "/login",
        state: { ...location.state, nextPathname: location.pathname }
      });
    }

    if (currentUser || error) {
      if (currentUser && !loading && !error) {
        onCompleted(currentUser);
      } else if (!loading && error) {
        onError(error);
      }
    }
  }, [currentUser, loading, error, setUser, loaded, history]);

  // set up and save current time on every click
  useEffect(() => {
    function setLastClickTime() {
      lastClickAtRef.current = moment();
    }

    document.addEventListener("click", setLastClickTime, false);
    return () => document.removeEventListener("click", setLastClickTime, false);
  }, []);

  // check if expired every 10s
  useEffect(() => {
    async function checkToken() {
      // if token is expired, immediately logout
      if (
        -moment().diff(tokenExpirationRef.current, "seconds") < 0 &&
        !expAlertOpen
      ) {
        history.replace({
          pathname: "/login",
          state: { nextPathname: location.pathname, sessionExpired: true }
        });
        window.sessionStorage.clear();
        // a reload resets the client store
        window.location.reload();
      }

      // else if expiration is less than 5 min...
      else if (
        -moment().diff(tokenExpirationRef.current, "seconds") <
          EXPIRATION_ALERT_DURATION &&
        !expAlertOpen
      ) {
        // if activity in last 10 min, refresh token
        if (
          moment().diff(lastClickAtRef.current, "seconds") <
          NON_ACTIVITY_DURATION
        ) {
          const { data } = await signInTo({
            variables: {
              companies: user.companies.edges.map(({ company }) => company.id)
            }
          });
          const { token, expiresAt } = data.signInTo;
          // save the new token to local storage
          window.sessionStorage.token = token;
          window.sessionStorage.tokenExpiresAt = expiresAt;
          // reset tokenExpRef
          tokenExpirationRef.current = moment(
            window.sessionStorage.tokenExpiresAt
          );
        } else {
          // if no activity within 10 min, display expiration alert
          setExpAlertOpen(true);
        }
      }
    }

    if (loaded) {
      const checkTokenTimerId = setInterval(() => {
        checkToken();
      }, 10000);
      return () => clearInterval(checkTokenTimerId);
    }
  }, [expAlertOpen, history, loaded, signInTo, user]);

  // HANDLES

  const handleStaySignedIn = async () => {
    setExpAlertOpen(false);
    const { data } = await signInTo({
      variables: {
        companies: user.companies.edges.map(({ company }) => company.id)
      }
    });
    const { token, expiresAt } = data.signInTo;
    // save the new token to local storage
    window.sessionStorage.token = token;
    window.sessionStorage.tokenExpiresAt = expiresAt;
    // reset tokenExpRef
    tokenExpirationRef.current = moment(window.sessionStorage.tokenExpiresAt);
  };

  // DOM

  if (!loaded) return <Loading overlay />;

  return (
    <>
      <Route
        {...rest}
        render={props =>
          user.id ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
      {expAlertOpen && (
        <AuthExpirationAlert
          open={expAlertOpen}
          onClose={() => setExpAlertOpen(false)}
          onStaySignedIn={handleStaySignedIn}
        />
      )}
    </>
  );
};

PrivateRoute.propTypes = propTypes;

export default withRouter(PrivateRoute);
