// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InboxMenuItem__badge{padding:0px 3px;min-width:16px;height:16px;border-radius:8px;background-color:#ff9800;color:#fff;position:absolute;top:6px;right:4px;font-size:12px;font-weight:600;display:none;align-items:center;justify-content:center}.InboxMenuItem__badge.InboxMenuItem__show{display:flex}.InboxMenuItem__link{cursor:pointer}.InboxMenuItem__image{width:180px;margin:auto}.InboxMenuItem__label{font-weight:bold;padding-left:5px}.InboxMenuItem__dropdownContainer{display:flex;width:250px;max-height:300px;flex-direction:column;justify-content:space-between}.InboxMenuItem__dropdownHeader{margin-top:15px;text-align:center;font-weight:bold;font-size:14px;height:30px;display:flex;align-items:center;margin-left:35px}.InboxMenuItem__dropdownBody{flex-direction:column;justify-content:center;align-items:center;overflow:scroll}.InboxMenuItem__dropdownFooter{display:flex;height:50px;text-align:center;flex-direction:column;justify-content:space-evenly}.InboxMenuItem__imgWrapper{display:flex;width:100%;padding:30px 0 20px 0}.InboxMenuItem__row{display:flex;align-items:center;justify-content:start;width:100%;font-weight:300;font-size:13px}.InboxMenuItem__item{display:flex;align-items:center;justify-content:space-between;width:100%}\n", ""]);
// Exports
exports.locals = {
	"badge": "InboxMenuItem__badge",
	"show": "InboxMenuItem__show",
	"link": "InboxMenuItem__link",
	"image": "InboxMenuItem__image",
	"label": "InboxMenuItem__label",
	"dropdownContainer": "InboxMenuItem__dropdownContainer",
	"dropdownHeader": "InboxMenuItem__dropdownHeader",
	"dropdownBody": "InboxMenuItem__dropdownBody",
	"dropdownFooter": "InboxMenuItem__dropdownFooter",
	"imgWrapper": "InboxMenuItem__imgWrapper",
	"row": "InboxMenuItem__row",
	"item": "InboxMenuItem__item"
};
module.exports = exports;
