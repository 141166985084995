import { blueGrey, cyan, red, orange } from "@material-ui/core/colors";
import VoxjarLogo from "../img/logo.png";
import FocalPointsLogo from "../img/focalpointslogo.svg";
import FocalPointsFav from "../img/fp-fav.svg";

import IP3ALogo from "../img/logoip3a.png";
import IP3A_FAV from "../img/IP3A_icon.png";

import PinpointLogo from "../img/pinpointlogo.png";
import Pinpoint_FAV from "../img/pinpoint_icon.jpg";

import CFELogo from "../img/cfelogo.png";
import CFE_FAV from "../img/cfeicon.png";

const IS_PRODUCTION = process.env.NODE_ENV === "production";

const OPTIONS = {
  localhost: {
    // Allows "route" creation
    CreateAccount: {
      route: true
    },
    ResetPassword: {
      route: true
    },
    Signup: {
      route: true
    },
    Integrations: {
      route: true
    },
    Inbox: {
      route: true,
      show: true
    },
    Dashboard: {
      route: true,
      show: true
    },
    Discover: {
      route: true,
      show: true
    },
    Scoring: {
      show: true,
      AgentTable: {
        route: true
      },
      Randomizer: {
        route: true
      }
    },
    Library: {
      route: true
    },
    Settings: {
      route: true,
      Personal: {
        route: true
      },
      Users: {
        route: true,
        allowUserManagement: true,
        allowManagerManagement: true
      },
      Teams: {
        route: true
      },
      Scorecards: {
        route: true
      },
      Metadata: {
        route: true
      },
      Integrations: {
        route: true
      },
      Developers: {
        route: true
      }
    },
    Onboarding: {
      route: true
    },
    Documentation: {
      main: "https://voxjar.com/resources/docs/overview/",
      five9: "https://voxjar.com/resources/docs/integrations/five9/",
      fuze: "https://voxjar.com/resources/docs/integrations/fuze/",
      incontact: "https://voxjar.com/resources/docs/integrations/incontact/",
      jive: "https://voxjar.com/resources/docs/integrations/jive/",
      orecx: "https://voxjar.com/resources/docs/integrations/orecx/",
      tcn: "https://voxjar.com/resources/docs/integrations/tcn/",
      ringcentral: "https://voxjar.com/resources/docs/integrations/ringcentral/"
    },
    // login:{oauth:{OIDC config "settings"}},
    login: { voxjar: true },
    navName: "Voxjar",
    contentName: "Voxjar",
    logo: VoxjarLogo,
    favicon: null,
    // TODO: somehow import these colors into theme.scss
    primaryColor: blueGrey[800],
    secondaryColor: cyan[400],
    accentColor: orange[500],
    errorColor: red[500],
    notificationEndpoint: "test.voxjar.com",
    supportEmail: "support@voxjar.com",
    calendly: "https://calendly.com/lori-brown-vo0/voxjar/invitees",
    origin: IS_PRODUCTION ? "https://app.voxjar.com" : "http://localhost:9002",
    language: null,
    redirectBaseUrl: IS_PRODUCTION
      ? "https://app.voxjar.com/oauth"
      : " http://localhost:9002/oauth"
  },
  "ia.focalpoints.ai": {
    // Allows "route" creation
    CreateAccount: {
      route: true
    },
    ResetPassword: {
      route: true
    },
    Signup: {
      route: true
    },
    Integrations: {
      route: true
    },
    Inbox: {
      route: true,
      show: true
    },
    Dashboard: {
      route: true,
      show: true
    },
    Discover: {
      route: true,
      show: true
    },
    Scoring: {
      show: true,
      AgentTable: {
        route: true
      },
      Randomizer: {
        route: true
      }
    },
    Library: {
      route: true
    },
    Settings: {
      route: true,
      Personal: {
        route: true
      },
      Users: {
        route: true,
        allowUserManagement: true,
        allowManagerManagement: true
      },
      Teams: {
        route: true
      },
      Scorecards: {
        route: true
      },
      Metadata: {
        route: true
      },
      Integrations: {
        route: true
      },
      Developers: {
        route: true
      }
    },
    Onboarding: {
      route: true
    },
    Documentation: {
      main: "https://voxjar.com/resources/docs/overview/",
      five9: "https://voxjar.com/resources/docs/integrations/five9/",
      fuze: "https://voxjar.com/resources/docs/integrations/fuze/",
      incontact: "https://voxjar.com/resources/docs/integrations/incontact/",
      jive: "https://voxjar.com/resources/docs/integrations/jive/",
      orecx: "https://voxjar.com/resources/docs/integrations/orecx/",
      tcn: "https://voxjar.com/resources/docs/integrations/tcn/",
      ringcentral: "https://voxjar.com/resources/docs/integrations/ringcentral/"
    },
    // login:{oauth:{OIDC config "settings"}},
    login: { voxjar: true },
    navName: "Interaction Analytics",
    contentName: "Interaction Analytics",
    logo: FocalPointsLogo,
    favicon: FocalPointsFav,
    // TODO: somehow import these colors into theme.scss
    primaryColor: "#1570EF",
    secondaryColor: "#A1C6F9",
    accentColor: cyan[400],
    errorColor: red[500],
    notificationEndpoint: "test.voxjar.com",
    supportEmail: "support@voxjar.com",
    calendly: "https://calendly.com/lori-brown-vo0/voxjar/invitees",
    origin: IS_PRODUCTION ? "https://app.voxjar.com" : "http://localhost:9002",
    language: null,
    redirectBaseUrl: IS_PRODUCTION
      ? "https://app.voxjar.com/oauth"
      : " http://localhost:9002/oauth"
  },
  "ip3a.voxjar.com": {
    // Allows "route" creation
    CreateAccount: {
      route: false
    },
    ResetPassword: {
      route: true
    },
    Signup: {
      route: true
    },
    Integrations: {
      route: false
    },
    Inbox: {
      route: true,
      show: false
    },
    Dashboard: {
      route: true,
      show: true
    },
    Discover: {
      route: true,
      show: true
    },
    Scoring: {
      show: true,
      AgentTable: {
        route: true
      },
      Randomizer: {
        route: true
      }
    },
    Library: {
      route: true
    },
    Settings: {
      route: true,
      Personal: {
        route: true
      },
      Users: {
        route: true,
        allowUserManagement: true,
        allowManagerManagement: true
      },
      Teams: {
        route: true
      },
      Scorecards: {
        route: true
      },
      Metadata: {
        route: true
      },
      Integrations: {
        route: false
      },
      Developers: {
        route: true
      }
    },
    Onboarding: {
      route: false
    },
    Documentation: {
      main: "https://voxjar.com/resources/docs/overview/",
      five9: "https://voxjar.com/resources/docs/integrations/five9/",
      fuze: "https://voxjar.com/resources/docs/integrations/fuze/",
      incontact: "https://voxjar.com/resources/docs/integrations/incontact/",
      jive: "https://voxjar.com/resources/docs/integrations/jive/",
      orecx: "https://voxjar.com/resources/docs/integrations/orecx/",
      tcn: "https://voxjar.com/resources/docs/integrations/tcn/",
      ringcentral: "https://voxjar.com/resources/docs/integrations/ringcentral/"
    },
    // login:{oauth:{OIDC config "settings"}},
    login: { voxjar: true },
    navName: "IP3A Analytics",
    contentName: "IP3A",
    logo: IP3ALogo,
    favicon: IP3A_FAV,
    // TODO: somehow import these colors into theme.scss
    primaryColor: "#4e4e50",
    secondaryColor: "#2fb8de",
    accentColor: orange[500],
    errorColor: red[500],
    notificationEndpoint: "test.voxjar.com",
    supportEmail: "support@voxjar.com",
    calendly: "https://calendly.com/lori-brown-vo0/voxjar/invitees",
    origin: IS_PRODUCTION ? "https://app.voxjar.com" : "http://localhost:9002",
    language: null,
    redirectBaseUrl: IS_PRODUCTION
      ? "https://app.voxjar.com/oauth"
      : " http://localhost:9002/oauth"
  },
  "pinpoint.voxjar.com": {
    // Allows "route" creation
    CreateAccount: {
      route: false
    },
    ResetPassword: {
      route: true
    },
    Signup: {
      route: true
    },
    Integrations: {
      route: false
    },
    Inbox: {
      route: true,
      show: false
    },
    Dashboard: {
      route: true,
      show: true
    },
    Discover: {
      route: true,
      show: true
    },
    Scoring: {
      show: true,
      AgentTable: {
        route: true
      },
      Randomizer: {
        route: true
      }
    },
    Library: {
      route: true
    },
    Settings: {
      route: true,
      Personal: {
        route: true
      },
      Users: {
        route: true,
        allowUserManagement: true,
        allowManagerManagement: true
      },
      Teams: {
        route: true
      },
      Scorecards: {
        route: true
      },
      Metadata: {
        route: true
      },
      Integrations: {
        route: false
      },
      Developers: {
        route: true
      }
    },
    Onboarding: {
      route: false
    },
    Documentation: {
      main: "https://voxjar.com/resources/docs/overview/",
      five9: "https://voxjar.com/resources/docs/integrations/five9/",
      fuze: "https://voxjar.com/resources/docs/integrations/fuze/",
      incontact: "https://voxjar.com/resources/docs/integrations/incontact/",
      jive: "https://voxjar.com/resources/docs/integrations/jive/",
      orecx: "https://voxjar.com/resources/docs/integrations/orecx/",
      tcn: "https://voxjar.com/resources/docs/integrations/tcn/",
      ringcentral: "https://voxjar.com/resources/docs/integrations/ringcentral/"
    },
    // login:{oauth:{OIDC config "settings"}},
    login: { voxjar: true },
    navName: "PinPoint Analytics",
    contentName: "PinPoint",
    logo: PinpointLogo,
    favicon: Pinpoint_FAV,
    // TODO: somehow import these colors into theme.scss
    primaryColor: blueGrey[800],
    secondaryColor: "#ea0e22",
    accentColor: orange[500],
    errorColor: red[500],
    notificationEndpoint: "test.voxjar.com",
    supportEmail: "support@voxjar.com",
    calendly: "https://calendly.com/lori-brown-vo0/voxjar/invitees",
    origin: IS_PRODUCTION ? "https://app.voxjar.com" : "http://localhost:9002",
    language: null,
    redirectBaseUrl: IS_PRODUCTION
      ? "https://app.voxjar.com/oauth"
      : " http://localhost:9002/oauth"
  },
  "voicebox.voxjar.com": {
    // Allows "route" creation
    CreateAccount: {
      route: false
    },
    ResetPassword: {
      route: true
    },
    Signup: {
      route: true
    },
    Integrations: {
      route: false
    },
    Inbox: {
      route: true,
      show: false
    },
    Dashboard: {
      route: true,
      show: true
    },
    Discover: {
      route: true,
      show: true
    },
    Scoring: {
      show: false,
      AgentTable: {
        route: false
      },
      Randomizer: {
        route: false
      }
    },
    Library: {
      route: false
    },
    Settings: {
      route: true,
      Personal: {
        route: true
      },
      Users: {
        route: true,
        allowUserManagement: true,
        allowManagerManagement: true
      },
      Teams: {
        route: true
      },
      Scorecards: {
        route: false
      },
      Metadata: {
        route: true
      },
      Integrations: {
        route: false
      },
      Developers: {
        route: true
      }
    },
    Onboarding: {
      route: false
    },
    Documentation: {
      main: "https://voxjar.com/resources/docs/overview/",
      five9: "https://voxjar.com/resources/docs/integrations/five9/",
      fuze: "https://voxjar.com/resources/docs/integrations/fuze/",
      incontact: "https://voxjar.com/resources/docs/integrations/incontact/",
      jive: "https://voxjar.com/resources/docs/integrations/jive/",
      orecx: "https://voxjar.com/resources/docs/integrations/orecx/",
      tcn: "https://voxjar.com/resources/docs/integrations/tcn/",
      ringcentral: "https://voxjar.com/resources/docs/integrations/ringcentral/"
    },
    // login:{oauth:{OIDC config "settings"}},
    login: { voxjar: true },
    navName: "Voicebox",
    contentName: "Voicebox",
    logo: PinpointLogo,
    favicon: Pinpoint_FAV,
    // TODO: somehow import these colors into theme.scss
    primaryColor: blueGrey[800],
    secondaryColor: "#ea0e22",
    accentColor: orange[500],
    errorColor: red[500],
    notificationEndpoint: "test.voxjar.com",
    supportEmail: "support@voxjar.com",
    calendly: "https://calendly.com/lori-brown-vo0/voxjar/invitees",
    origin: IS_PRODUCTION ? "https://app.voxjar.com" : "http://localhost:9002",
    language: null,
    redirectBaseUrl: IS_PRODUCTION
      ? "https://app.voxjar.com/oauth"
      : " http://localhost:9002/oauth"
  },
  "dev.voxjar.com": {
    // Allows "route" creation
    CreateAccount: {
      route: true
    },
    ResetPassword: {
      route: true
    },
    Signup: {
      route: true
    },
    Integrations: {
      route: true
    },
    Inbox: {
      route: true,
      show: true
    },
    Dashboard: {
      route: true,
      show: true
    },
    Discover: {
      route: true,
      show: true
    },
    Scoring: {
      show: true,
      AgentTable: {
        route: true
      },
      Randomizer: {
        route: true
      }
    },
    Library: {
      route: true
    },
    Settings: {
      route: true,
      Personal: {
        route: true
      },
      Users: {
        route: true,
        allowUserManagement: true,
        allowManagerManagement: true
      },
      Teams: {
        route: true
      },
      Scorecards: {
        route: true
      },
      Metadata: {
        route: true
      },
      Integrations: {
        route: true
      },
      Developers: {
        route: true
      }
    },
    Onboarding: {
      route: true
    },
    Documentation: {
      main: "https://voxjar.com/resources/docs/overview/",
      five9: "https://voxjar.com/resources/docs/integrations/five9/",
      fuze: "https://voxjar.com/resources/docs/integrations/fuze/",
      incontact: "https://voxjar.com/resources/docs/integrations/incontact/",
      jive: "https://voxjar.com/resources/docs/integrations/jive/",
      orecx: "https://voxjar.com/resources/docs/integrations/orecx/",
      tcn: "https://voxjar.com/resources/docs/integrations/tcn/",
      ringcentral: "https://voxjar.com/resources/docs/integrations/ringcentral/"
    },
    // login:{oauth:{OIDC config "settings"}},
    login: { voxjar: true },
    navName: "Voxjar",
    contentName: "Voxjar",
    logo: VoxjarLogo,
    favicon: null,
    primaryColor: blueGrey[800],
    secondaryColor: cyan[400],
    accentColor: orange[500],
    errorColor: red[500],
    notificationEndpoint: "test.voxjar.com",
    supportEmail: "support@voxjar.com",
    calendly: "https://calendly.com/lori-brown-vo0/voxjar/invitees",
    origin: IS_PRODUCTION
      ? "https://app.voxjar.com"
      : /*"http://localhost:9002";*/ "https://dev.voxjar.com",
    language: null,
    redirectBaseUrl: IS_PRODUCTION
      ? "https://app.voxjar.com/oauth"
      : /*" http://localhost:9002/oauth/"*/ "https://dev.voxjar.com/oauth"
  },
  "cfe.voxjar.com": {
    // Allows "route" creation
    CreateAccount: {
      route: false
    },
    ResetPassword: {
      route: true
    },
    Signup: {
      route: true
    },
    Integrations: {
      route: false
    },
    Inbox: {
      route: true,
      show: false
    },
    Dashboard: {
      route: true,
      show: true
    },
    Discover: {
      route: true,
      show: true
    },
    Scoring: {
      show: true,
      AgentTable: {
        route: true
      },
      Randomizer: {
        route: true
      }
    },
    Library: {
      route: true
    },
    Settings: {
      route: true,
      Personal: {
        route: true
      },
      Users: {
        route: true,
        allowUserManagement: true,
        allowManagerManagement: true
      },
      Teams: {
        route: false
      },
      Scorecards: {
        route: true
      },
      Metadata: {
        route: false
      },
      Integrations: {
        route: false
      },
      Developers: {
        route: true
      }
    },
    Onboarding: {
      route: false
    },
    Documentation: {
      main: "https://voxjar.com/resources/docs/overview/",
      five9: "https://voxjar.com/resources/docs/integrations/five9/",
      fuze: "https://voxjar.com/resources/docs/integrations/fuze/",
      incontact: "https://voxjar.com/resources/docs/integrations/incontact/",
      jive: "https://voxjar.com/resources/docs/integrations/jive/",
      orecx: "https://voxjar.com/resources/docs/integrations/orecx/",
      tcn: "https://voxjar.com/resources/docs/integrations/tcn/",
      ringcentral: "https://voxjar.com/resources/docs/integrations/ringcentral/"
    },
    // login:{oauth:{OIDC config "settings"}},
    login: { voxjar: true },
    navName: "CFE Analytics",
    contentName: "CFE",
    logo: CFELogo,
    favicon: CFE_FAV,
    // TODO: somehow import these colors into theme.scss
    primaryColor: blueGrey[800],
    secondaryColor: cyan[400],
    accentColor: orange[500],
    errorColor: red[500],
    notificationEndpoint: "test.voxjar.com",
    supportEmail: "support@voxjar.com",
    calendly: "https://calendly.com/lori-brown-vo0/voxjar/invitees",
    origin: IS_PRODUCTION ? "https://app.voxjar.com" : "http://localhost:9002",
    language: null,
    redirectBaseUrl: IS_PRODUCTION
      ? "https://app.voxjar.com/oauth"
      : " http://localhost:9002/oauth"
  }
};

export { OPTIONS };
